import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiUrl } from 'shared/enums/api-url.enum';
import { AccessData } from 'shared/interfaces/authentication.interface';
import { UserProfileService } from 'shared/services/user-profile.service';

import { AlertService } from './alert.service';
import { I18nService } from './i18n.service';

@Injectable()
export class AuthenticationService {
  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    private i18nService: I18nService,
    private router: Router,
    private userService: UserProfileService,
  ) { }

  isLoggedIn(): boolean {
    return !!this.userService.getCurrentUserProfile() && !!this.userService.getCurrentUserProfile().username;
  }

  logIn(accessData: AccessData): Promise<void> {
    return this.http.post(
      `${ApiUrl.authApi}`,
      {
        CustomerID: accessData.clientId,
        Username: accessData.username,
        Password: accessData.password
      },
      { responseType: 'text'}
    ).toPromise()
      .then(async () => {
        await this.userService.fetchUserProfile();
        await this.i18nService.setLanguage();
        this.router.navigate(['/ehs']);
      })
      .catch((error: Error) => {
        this.alertService.error('login');
        throw error;
      });
  }

  logOut(): Promise<void> {
    return this.http.post(
      `${ApiUrl.userApi}/logout`,
      {}
    ).toPromise()
      .then((data: any) => {
        this.userService.deleteCurrentUserProfile();
        this.i18nService.setLanguage();
        this.router.navigate(['ehs/login']);
      })
      .catch((error: Error) => {
        this.alertService.error();
        throw error;
      });
  }
}
