import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-unsafe-condition',
  templateUrl: './unsafe-condition.component.pug',
  styleUrls: ['./unsafe-condition.component.scss']
})
export class UnsafeConditionComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
