import { Injectable, ElementRef } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  userAgent = window.navigator.userAgent;

  constructor() { }

  get windowScrollY(): number {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    const windowScrollY = supportPageOffset ?
      window.pageYOffset :
      isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
    return windowScrollY;
  }

  scrollView(direction: string, scrollContainer?: ElementRef): Promise<any> {
    const scrollInterval = direction === 'down' ? 5 : -5;
    let scrollValue = 0;
    const scrollFunction = scrollContainer && scrollContainer.nativeElement ?
      () => { scrollContainer.nativeElement.scrollTop += scrollInterval; } :
      () => { window.scrollBy(0, scrollInterval); };
    return new Promise((resolve, reject) => {
      const timerID = setInterval(() => {
        scrollFunction();
        scrollValue++;
        if (scrollValue >= 10) {
          clearInterval(timerID);
          resolve();
        }
      }, 16);
    });
  }

  isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(this.userAgent) && !window['MSStream'];
  }

  mergeObjects(objectOne: any, objectTwo: any): any {
    const removeUndefined = (object: any) => {
      for (const key in object) {
        if (object[key] === undefined) {
          delete object[key];
        }
      }
      return object;
    };

    return Object.assign({}, objectOne, removeUndefined(objectTwo));
  }

  isNullable(value: any): boolean {
    return value === undefined || value === null;
  }

  areArraysDifferent(firstArray: Array<any>, secondArray: Array<any>, key?: string): boolean {
    if (!firstArray && !secondArray) {
      return false;
    } else if (firstArray && !secondArray || !firstArray && secondArray || firstArray.length !== secondArray.length) {
      return true;
    } else {
      for (const firstItem of firstArray) {
        const exists = !!_.find(secondArray, (secondItem) => key ? secondItem[key] === firstItem[key] : secondItem === firstItem);
        if (!exists) {
          return true;
        }
      }
      return false;
    }
  }
}
