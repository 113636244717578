import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
// Enums
import { TileHeight } from 'shared/components/tiles/tiles.component';
import { MeasureTypes } from 'shared/enums/measure-types.enum';
// Interfaces
import { Tile } from 'shared/interfaces/tile.interface';
// Services
import { AccidentStepperService } from 'shared/services/accident-stepper.service';
import { AccidentService } from 'shared/services/accident.service';
import { AlertService } from 'shared/services/alert.service';

@Component({
  selector: 'gw-accident-step3',
  templateUrl: './accident-step3.component.pug',
  styleUrls: ['./accident-step3.component.scss']
})
export class AccidentStep3Component implements OnInit {
  translation = {
    measures: {
      firstAid: '',
      consultation: '',
      rescueOperation: '',
      other: ''
    }
  };
  tiles: Array<Tile>;
  selectedMeasures: Array<string> = new Array<string>();
  measuresForm: FormGroup;
  submitted = false;
  tileHeights = TileHeight;
  isSaving: boolean;

  constructor(
    private accidentService: AccidentService,
    private accidentStepperService: AccidentStepperService,
    private alertService: AlertService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private translateService: TranslateService
  ) { }

  async ngOnInit() {
    await this.fetchTranslation();
    this.createTiles();
    this.initForm();
  }

  private initForm(): void {
    const stepData = this.accidentStepperService.formSteps.step3;

    this.measuresForm = this.formBuilder.group({
      measures: [[], Validators.required]
    });

    if (stepData && stepData.remark) {
      this.addRemarkControl(stepData.remark);
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  private createTiles(): void {
    this.tiles = [
      {
        id: MeasureTypes.firstAid,
        svgUrl: '/assets/icons/sprite-measures.svg#first-aid-kit',
        translationString: this.translation.measures.firstAid,
        smallIcon: true
      },
      {
        id: MeasureTypes.consultation,
        svgUrl: '/assets/icons/sprite-measures.svg#sthetoscope',
        translationString: this.translation.measures.consultation,
        smallIcon: true
      },
      {
        id: MeasureTypes.rescueOperation,
        svgUrl: '/assets/icons/sprite-measures.svg#ambulance',
        translationString: this.translation.measures.rescueOperation,
        smallIcon: true
      },
      {
        id: MeasureTypes.other,
        svgUrl: '/assets/icons/sprite-measures.svg#more',
        translationString: this.translation.measures.other,
        smallIcon: true
      },
    ];
  }

  toggleTile(tile: Tile): void {
    const tileIndex = this.selectedMeasures.indexOf(tile.id);
    if (tileIndex !== -1) {
      this.selectedMeasures.splice(tileIndex, 1);

      if (tile.id === MeasureTypes.other) {
        this.removeRemarkControl();
      }
    } else {
      this.selectedMeasures.push(tile.id);

      if (tile.id === MeasureTypes.other) {
        this.addRemarkControl('');
      }
    }
    this.measuresForm.controls.measures.setValue(this.selectedMeasures);
  }

  addRemarkControl(value: string): void {
    this.measuresForm.addControl('remark', new FormControl(value, Validators.required));
    this.changeDetector.detectChanges();
  }

  removeRemarkControl(): void {
    this.measuresForm.removeControl('remark');
  }

  async submitForm(form: FormGroup): Promise<void> {
    this.submitted = true;

    if (form.valid) {
      this.isSaving = true;
      await this.updateStep(form);
      this.accidentService.reportAccident(
        this.accidentStepperService.getFormData()
      )
        .then(() => {
          this.alertService.success();
          this.router.navigate(['/esh']);
        })
        .catch(() => {
          this.alertService.error();
          this.isSaving = false;
        });
    }
  }

  updateStep(form: FormGroup): void {
    this.accidentStepperService.formSteps.step3 = this.accidentStepperService.convertStep3FormToObject(form);
    this.accidentStepperService.updateInStorage();
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'EHS.ACCIDENT.STEP_3.MEASURES.FIRST_AID',
      'EHS.ACCIDENT.STEP_3.MEASURES.CONSULTATION',
      'EHS.ACCIDENT.STEP_3.MEASURES.RESCUE_OPERATION',
      'EHS.ACCIDENT.STEP_3.MEASURES.OTHER',
    ]).toPromise();
    this.translation.measures.firstAid = translation['EHS.ACCIDENT.STEP_3.MEASURES.FIRST_AID'];
    this.translation.measures.consultation = translation['EHS.ACCIDENT.STEP_3.MEASURES.CONSULTATION'];
    this.translation.measures.rescueOperation = translation['EHS.ACCIDENT.STEP_3.MEASURES.RESCUE_OPERATION'];
    this.translation.measures.other = translation['EHS.ACCIDENT.STEP_3.MEASURES.OTHER'];
  }
}
