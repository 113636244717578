import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
// Directives
import { SimpleDropdownDirective } from 'shared/directives/simple-dropdown.directive';

class SelectItem {
  key: any;
  name: string;
  data: any;

  _type = 'SelectItem';

  constructor(selectGroup: any) {
    this.key = selectGroup.key;
    this.name = selectGroup.name;
    this.data = selectGroup.data;
  }
}

export class SelectGroup {
  name: string;
  data: Array<any>;

  _type = 'SelectGroup';

  constructor(selectGroup: any) {
    this.name = selectGroup.name;
    this.data = selectGroup.data;
  }
}

@Component({
  selector: 'gw-select',
  templateUrl: './select.component.pug',
  styleUrls: ['./select.component.scss'],
  exportAs: 'gwSelect'
})
export class SelectComponent implements OnInit {
  @ViewChild('selectDropdown') selectDropdown: SimpleDropdownDirective;
  @ViewChild('scrollView') scrollView: ElementRef;
  @ViewChildren('itemElement') itemsElements: QueryList<ElementRef>;
  @Input() data: Array<any>;
  @Input() display: Array<string>;
  @Input() nestedValue: string;
  @Input() selected: any;
  @Input() uniqueKey: string;
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() white: boolean;
  @Input() small: boolean;
  @Input() search: boolean;
  @Input() disabled: boolean;
  @Input() error: boolean;
  @Input() splitter = ' ';
  @Output() output = new EventEmitter<any>();
  options: Array<SelectItem | SelectGroup>;
  searchQuery: string;
  focusedOption: SelectItem;

  constructor() { }

  ngOnInit() {
    this.init();
  }

  init(): void {
    this.generateOptions();
  }

  generateOptions(query?: string): void {
    if (query) {
      query = query.toLowerCase();
    }
    this.options = new Array<SelectItem | SelectGroup>();
    if (this.data) {
      this.data.forEach(object => {
        if (object[this.nestedValue]) {
          const selectGroupItems = new Array<SelectItem>();
          object[this.nestedValue].forEach(nestedObject => {
            const optionName = this.getDisplay(nestedObject);
            if (!query || ~optionName.toLowerCase().indexOf(query)) {
              const option = new SelectItem({
                key: nestedObject[this.uniqueKey],
                name: optionName,
                data: nestedObject
              });
              selectGroupItems.push(option);
              if (this.isSelected(option)) {
                this.focusedOption = option;
              }
            }
          });
          this.options.push(new SelectGroup({
            name: this.getDisplay(object),
            data: selectGroupItems
          }));
        } else {
          const optionName = this.getDisplay(object);
          if (!query || ~optionName.toLowerCase().indexOf(query)) {
            const option = new SelectItem({
              key: object[this.uniqueKey],
              name: this.getDisplay(object),
              data: object
            });
            this.options.push(option);
            if (this.isSelected(option)) {
              this.focusedOption = option;
            }
          }
        }
      });
      if (!this.focusedOption && this.options.length) {
        if (this.isGroup(this.options[0])) {
          this.focusedOption = this.options[0].data[0];
        } else {
          this.focusedOption = new SelectItem(this.options[0]);
        }
      }
    }
  }

  openOptions(): void {
    this.init();
    this.selectDropdown.openDropdown();
  }

  focusDropdown(): void {
    this.selectDropdown.focusOnDropdown();
  }

  getDisplay(item): string {
    let display = '';
    let iterator = 0;
    for (let i = 0; i < this.display.length; i++) {
      const displayedItem = this.display[i].split('.').reduce((o, index) => o[index], item);
      if (displayedItem) {
        if (iterator > 0) {
          display += this.splitter;
        }
        display += displayedItem;
        iterator++;
      }
    }
    return display;
  }

  isGroup(item): boolean {
    return item._type === 'SelectGroup';
  }

  isSelected(item: SelectItem): boolean {
    return this.selected && this.selected[this.uniqueKey] === item.key;
  }

  isFocused(item: SelectItem): boolean {
    return this.focusedOption && this.focusedOption.key === item.key;
  }

  selectItem(item: SelectItem): void {
    this.selected = item.data;
    this.focusedOption = item;
    this.submit();
  }

  getItemAbsoluteIndex(item: SelectItem): number {
    let index = -1;
    for (const option of this.options) {
      if (!this.isGroup(option)) {
        index++;
        if (option['key'] === item.key) {
          return index;
        }
      } else {
        for (const nestedOption of option.data) {
          index++;
          if (nestedOption['key'] === item.key) {
            return index;
          }
        }
      }
    }
  }

  scrollToItem(item: SelectItem): void {
    const itemIndex = this.getItemAbsoluteIndex(item);
    const itemElement = this.itemsElements.toArray()[itemIndex];
    this.scrollView.nativeElement.scrollTop = itemElement.nativeElement.offsetTop - this.scrollView.nativeElement.offsetTop;
  }

  submit(): void {
    this.output.emit(this.selected);
  }

  getAnotherOption(
    direction: string,
    currentOption: SelectItem,
    options: Array<any> = this.options
  ): SelectItem {
    const moveUp = direction === 'previous';
    if (options) {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (!this.isGroup(option)) {
          if (option.key === currentOption.key) {
            return this.chooseAnotherOption(i, options, moveUp);
          }
        } else {
          for (let j = 0; j < option.data.length; j++) {
            const nestedOption = option.data[j];
            if (nestedOption.key === currentOption.key) {
              const focusedOption = this.chooseAnotherOption(j, option.data, moveUp, true);
              return focusedOption ? focusedOption : this.chooseAnotherOption(i, options, moveUp);
            }
          }
        }
      }
    }
  }

  chooseAnotherOption(index: number, options: Array<any>, moveUp: boolean, isChild?: boolean): SelectItem {
    const anotherIndex = moveUp ? index - 1 : index + 1;
    if (moveUp && anotherIndex >= 0 || !moveUp && anotherIndex < options.length) {
      if (!this.isGroup(options[anotherIndex])) {
        return options[anotherIndex];
      } else {
        const nestedIndex = moveUp ? options[anotherIndex].data.length - 1 : 0;
        return options[anotherIndex].data[nestedIndex];
      }
    } else if (!isChild) {
      const loopIndex = moveUp ? options.length - 1 : 0;
      if (!this.isGroup(options[loopIndex])) {
        return options[loopIndex];
      } else {
        const nestedIndex = moveUp ? options[loopIndex].data.length - 1 : 0;
        return options[loopIndex].data[nestedIndex];
      }
    } else {
      return;
    }
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    switch (event.key) {
      case 'Tab':
        if (this.selectDropdown.openedWithDelay) {
          this.selectDropdown.closeDropdown();
        }
        break;
      case 'Enter':
        if (this.selectDropdown.openedWithDelay) {
          event.preventDefault();
          this.selectDropdown.closeDropdown();
          if (this.focusedOption) {
            this.selectItem(this.focusedOption);
          }
        }
        return false;
      case 'ArrowUp':
        event.preventDefault();
        if (this.selectDropdown.openedWithDelay) {
          this.focusedOption = this.getAnotherOption('previous', this.focusedOption);
          if (this.focusedOption) {
            this.scrollToItem(this.focusedOption);
          }
        } else {
          this.openOptions();
        }
        return false;
      case 'ArrowDown':
        event.preventDefault();
        if (this.selectDropdown.openedWithDelay) {
          this.focusedOption = this.getAnotherOption('next', this.focusedOption);
          if (this.focusedOption) {
            this.scrollToItem(this.focusedOption);
          }
        } else {
          this.openOptions();
        }
        return false;
    }
  }
}
