import { Component, OnInit, Input, Output, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gw-check-box',
  exportAs: 'gwCheckBox',
  template: `
    <button
      class="check-box"
      type="button"
      tabindex="{{ tabindex }}"
      [class.check-box--checked]="value"
      [class.check-box--parent-checked]="parentChecked"
      (click)="click()"
      [disabled]="disabled"
    >
      <i class="gw-check check-box__icon" [class.check-box__icon--visible]="value"></i>
    </button>
  `,
  styleUrls: ['./check-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxComponent),
      multi: true
    }
  ]
})
export class CheckBoxComponent implements OnInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() parentChecked: boolean;
  @Input() tabindex: number;
  value: boolean;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() { }

  onChange = (_: boolean) => {};

  writeValue(value: boolean) {
    if (value !== undefined) {
      this.value = value;
      this.changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  click() {
    if (!this.disabled) {
      this.value = !this.value;
      this.onChange(this.value);
    }
  }
}
