import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
// Modals
import { Attachment } from 'shared/models/attachment.model';
// Services
import { FileService } from 'shared/services/file.service';
import { ModalService } from 'shared/services/modal.service';

class AttachmentsGroup {
  images: Array<Attachment>;
  others: Array<Attachment>;

  constructor() {
    this.images = new Array<Attachment>();
    this.others = new Array<Attachment>();
  }
}

@Component({
  selector: 'gw-attachments',
  templateUrl: './attachments.component.pug',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
  @Input() set allAttachments(attachments: Attachment[]) {
    if (attachments) {
      this.attachments = new AttachmentsGroup();
      attachments.forEach((attachment: Attachment) => {
        this.pushAttachmentToArray(attachment, this.attachments);
      });
    }
  }
  @Input() tabindex: number;
  @Output() attachmentsChange = new EventEmitter();
  attachments = new AttachmentsGroup();
  duplications = new AttachmentsGroup();

  constructor(
    private fileService: FileService,
    private modalService: ModalService
  ) {  }

  ngOnInit() { }

  async attachFiles(event): Promise<void> {
    this.clearDuplications();
    const fileList: FileList = event.target.files;
    let fileCounter = 0;

    if (fileList && fileList.length > 0) {
      const allAttachments = [...this.attachments.images, ...this.attachments.others];
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];

        const { hash, rotation } = await this.fileService.readFile(file);
        const attachment = new Attachment({ file });
        attachment.checksum = hash;
        attachment.rotation = rotation;
        fileCounter++;

        this.fileService.isDuplicated(attachment, allAttachments) ?
          this.pushAttachmentToArray(attachment, this.duplications) :
          this.pushAttachmentToArray(attachment, this.attachments);

        if (fileCounter === fileList.length && (this.duplications.images.length || this.duplications.others.length)) {
          this.modalService.open('duplicationsModal');
        }
      }
      this.emitAttachmentsChange();
    }
  }

  private clearDuplications(): void {
    this.duplications = new AttachmentsGroup();
  }

  private pushAttachmentToArray(attachment: Attachment, arrayGroup: { images: Array<Attachment>, others: Array<Attachment> }): void {
    attachment.isImage ?
      arrayGroup.images.push(attachment) :
      arrayGroup.others.push(attachment);
  }

  private emitAttachmentsChange(): void {
    this.attachmentsChange.emit([...this.attachments.images, ...this.attachments.others]);
  }

  spliceAttachment(attachment: Attachment): void {
    const array = attachment.isImage ? this.attachments.images : this.attachments.others;
    const index = array.indexOf(attachment);
    array.splice(index, 1);
  }

  removeAttachment(attachment: Attachment): void {
    this.spliceAttachment(attachment);
    this.emitAttachmentsChange();
  }

  keyDownAttachmentControl(event: KeyboardEvent, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }
}
