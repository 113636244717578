import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gw-title-bar',
  templateUrl: './title-bar.component.pug',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnInit {
  @Input() title: string;
  @Input() url: string;

  constructor(private router: Router) { }

  ngOnInit() {}

  navigateToUrl(): void {
    this.router.navigate([this.url]);
  }
}
