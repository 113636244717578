import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { BodyPart } from 'shared/models/body-part.model';
import { BodyPartService } from 'shared/services/body-part.service';

@Injectable()
export class BodyPartsResolve implements Resolve<any> {
  constructor(private bodyPartService: BodyPartService) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Array<BodyPart>> {
    return this.bodyPartService.getBodyParts();
  }
}
