import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from 'shared/services/authentication.service';
import { UserProfileService } from 'shared/services/user-profile.service';

@Component({
  selector: 'gw-login-page',
  templateUrl: './login-page.component.pug',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements OnInit {
  redirectUrl: string;
  loginForm: FormGroup;
  submitted = false;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserProfileService
  ) { }

  ngOnInit() {
    // try auth users from old app
    this.userService.fetchUserProfile()
      .then(() => {
        this.router.navigate(['/esh']);
      });

    this.initForm();
  }

  initForm(): void {
    this.loginForm = this.formBuilder.group({
      clientId: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  isFieldInvalid(field: FormControl): boolean {
    return !field.valid && this.submitted;
  }

  isFieldEmpty(field: FormControl): boolean {
    return field.errors &&  field.errors.required;
  }

  isClientIdValid(field: FormControl): boolean {
    return isNaN(field.value);
  }

  logIn(loginForm: FormGroup): void {
    this.submitted = true;
    if (loginForm.valid) {
      this.authenticationService.logIn(loginForm.value)
        .then(token => {
          if (this.authenticationService.isLoggedIn()) {
            this.router.navigate(['/esh']);
          }
        });
    }
  }
}
