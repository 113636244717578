import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { ApiUrl } from 'shared/enums/api-url.enum';
import { ApiUserProfile } from 'shared/interfaces/user-profile.interface';
import { UserProfile } from 'shared/models/user-profile.model';
import { StorageService } from 'shared/services/storage.service';

@Injectable()
export class UserProfileService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  fetchUserProfile(): Promise<UserProfile> {
    return this.http.get(`${ApiUrl.userApi}/profile`).toPromise()
      .then((userProfile: ApiUserProfile) => {
        const convertedUserProfile = this.convertUserToGet(userProfile);
        this.setCurrentUserProfile(convertedUserProfile);
        return convertedUserProfile;
      })
      .catch(async error => {
        throw error;
      });
  }

  setCurrentUserProfile(userProfile: UserProfile): void {
    this.storageService.setLocalStorage('currentUser', userProfile);
  }

  getCurrentUserProfile(): UserProfile {
    return this.storageService.getLocalStorage('currentUser');
  }

  deleteCurrentUserProfile(): void {
    this.storageService.removeFromLocalStorage('currentUser');
  }

  convertUserToGet(user: ApiUserProfile): UserProfile {
    return {
      username: user.Username,
      firstname: user.Firstname,
      lastname: user.Lastname,
      language: user.CultureName
    };
  }

}
