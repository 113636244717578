import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

// Services
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class I18nService {

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService
  ) { }

  setLanguage(): void {
    let language = this.translateService.getDefaultLang();
    const currentUser = this.storageService.getLocalStorage('currentUser');

    if (currentUser) {
      switch (currentUser.language) {
        case 'en-US':
          language = 'en';
          break;

        case 'en-EN':
          language = 'en';
          break;

        case 'de-DE':
          language = 'de';
          break;

        case 'de-AT':
          language = 'de';
          break;
      }
    }

    this.translateService.use(language);
    moment.locale(language);
  }
}
