import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import moment from 'moment';
import { RiskLevelConverter } from 'shared/converters/risk-level.converter';
import { ApiUrl } from 'shared/enums/api-url.enum';
import { MaterialDamage } from 'shared/enums/material-damage.enum';
import { ApiNearMiss } from 'shared/interfaces/near-miss.interface';
import { Attachment } from 'shared/models/attachment.model';
import { NearMiss } from 'shared/models/near-miss.model';

@Injectable({
  providedIn: 'root'
})

export class NearMissService {
  nearMissID: number;

  constructor(
    private http: HttpClient
  ) { }

  addNearMiss(nearMiss: NearMiss): Promise<void> {
    return this.http.post(
      `${ApiUrl.oamApi}/accident`,
      this.convertReportedNearMissToPost(nearMiss)
    ).toPromise()
      .then((nearMissID: number) => {
        this.nearMissID = nearMissID;
      })
      .catch(async error => {
        throw error;
      });
  }

  addAttachment(payload: { id: number, attachment: Attachment }): Promise<Object> {
    const formData: FormData = new FormData();
    let params: HttpParams = new HttpParams();
    formData.append('attachment', payload.attachment.file);
    params = params.append('accidentID', payload.id.toString());

    return this.http.post(`${ApiUrl.oamApi}/accident/addAttachment`, formData, { params }).toPromise()
      .catch((error) => {
        throw error;
      });
  }

  addAllAttachments(attachments: Array<Attachment>): Promise<Array<Attachment>> {
    const attachmentsPromises = [];

    attachments.forEach(attachment => {
      attachmentsPromises.push(this.addAttachment({ id: this.nearMissID, attachment }));
    });

    return Promise.all(attachmentsPromises)
      .catch((error) => {
        throw error;
      });
  }

  async reportNearMiss(nearMiss: NearMiss): Promise<void> {
    await this.addNearMiss(nearMiss);

    if (nearMiss.attachments) {
      await this.addAllAttachments(nearMiss.attachments);
    }
  }

  convertReportedNearMissToPost(nearMiss: NearMiss): ApiNearMiss {
    return {
      AccidentType: 'NearMiss',
      Date: moment(nearMiss.date).format(),
      Location: nearMiss.location,
      Description: nearMiss.description,
      ReportEmployeeID: nearMiss.reportedBy.id,
      WitnessesEmployeeIDs: nearMiss.witnesses.map(witness => witness.id),
      MaterialDamage: nearMiss.damages === MaterialDamage.yes,
      MaterialDamageDescription: nearMiss.damageDescription,
      PotentialRiskLevel: RiskLevelConverter.convertRiskLevelToPost(nearMiss.risk)
    };
  }
}
