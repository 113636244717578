import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import moment from 'moment';
// Components
import { DateTimePickerComponent } from 'shared/components/date-time-picker/date-time-picker.component';
// Directives
import { SimpleDropdownDirective } from 'shared/directives/simple-dropdown.directive';
// Models
import { Employee } from 'shared/models/employee.model';
import { EmployeeService } from 'shared/services/employee.service';
// Services
import { UnsafeConditionStepperService } from 'shared/services/unsafe-condition-stepper.service';

@Component({
  selector: 'gw-unsafe-condition-step1',
  templateUrl: './unsafe-condition-step1.component.pug',
  styleUrls: ['./unsafe-condition-step1.component.scss']
})
export class UnsafeConditionStep1Component implements OnInit {
  @ViewChild('dateTimeDropdown') dateTimeDropdown: SimpleDropdownDirective;
  @ViewChild('dateTimePicker') dateTimePicker: DateTimePickerComponent;
  employees: Array<Employee>;
  unsafeConditionForm: FormGroup;
  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private unsafeConditionStepperService: UnsafeConditionStepperService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this.employees = this.route.snapshot.data['employees'];
    this.initForm();
  }

  private initForm(): void {
    const stepData: any = this.unsafeConditionStepperService.formSteps.step1;
    this.unsafeConditionForm = this.formBuilder.group({
      date: [stepData && stepData.date || '', Validators.required],
      location: [stepData && stepData.location || '', Validators.required],
      reportedBy: [stepData && stepData.reportedBy || this.employeeService.getDefaultEmployee() || '', Validators.required],
    });
  }

  submitForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateStep(form);
      this.goToNextStep();
    }
  }

  async updateStep(form: FormGroup): Promise<void> {
    this.unsafeConditionStepperService.formSteps.step1 = this.unsafeConditionStepperService.convertStep1FormToObject(form);
    await this.unsafeConditionStepperService.updateInStorage();
  }

  goToNextStep(): void {
    this.router.navigate(['/ehs/unsafe-condition/description'], { replaceUrl: true });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  setControlValue(data: any, key: string): void {
    this.unsafeConditionForm.controls[key].setValue(data);
  }

  applyDate(momentDate: moment.Moment): void {
    const date = moment(momentDate).format('YYYY-MM-DD HH:mm'); // ISO format
    this.unsafeConditionForm.controls['date'].setValue(date);
    if (this.dateTimeDropdown) {
      this.dateTimeDropdown.closeDropdown();
    }
  }

  keyDownDateTimePicker(event: KeyboardEvent): void {
    if (this.dateTimePicker) {
      this.dateTimePicker.keydown(event);
    }
  }
}
