import { OnChanges, SimpleChange } from '@angular/core';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'gw-date-picker',
  templateUrl: './date-picker.component.pug',
  styleUrls: ['./date-picker.component.scss'],
  exportAs: 'gwDatePicker'
})
export class DatePickerComponent implements OnChanges {
  @Input() date: any;
  @Input() before: any;
  @Input() after: any;
  @Input() options: any;
  @Output() setDate = new EventEmitter<any>();

  months: any = [];

  defaultOptions = {
    'months': 1,
    'start': '',
    'callback': '',
    'mode': 'simple'
  };

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    this.options = this.options || this.defaultOptions || {};
    this.setup();
    this.prepare();
  }

  select(day: any, event: MouseEvent) {
    event.stopPropagation();
    if (!day.disabled) {
      switch (this.options.mode) {
        case 'multiple':
          this.date.push(moment(day.date));
          break;
        default:
          this.date = day.date;
          this.setDate.emit(this.date);
      }
      if (this.options.callback) {
        this.options.callback(this.date);
      }
      return this.prepare();
    }
  }

  moveMonth(step: any, event: MouseEvent) {
    event.stopPropagation();
    this.options.start.add(step, 'month');
    this.prepare();
  }

  private isStartEmpty(start): boolean {
    if (Array.isArray(start)) {
      if (start.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (start) {
      return false;
    } else {
      return true;
    }
  }

  private setup(): void {
    let value: any, start: any;
    const tempOptions = {};
    for (const attr in this.defaultOptions) {
      if (this.defaultOptions.hasOwnProperty(attr)) {
        value = this.defaultOptions[attr];
        tempOptions[attr] = value;
      }
    }
    if (this.options) {
      for (const attr in this.options) {
        if (this.options.hasOwnProperty(attr)) {
          value = this.options[attr];
          tempOptions[attr] = this.options[attr];
        }
      }
    }
    this.options = Object.assign(this.options, tempOptions);
    if (this.date && Array.isArray(this.date) && this.date.length > 0) {
      start = this.date[0];
    } else {
      start = this.date;
    }
    this.options.start =
      !this.isStartEmpty(start) ? moment(start) : this.options.start || moment().startOf('day');
  }

  private prepare(): void {
    let m: any;
    this.months = [];
    this.months = (() => {
      let i: any, ref: any, results: any;
      results = [];
      for (
        m = i = 0, ref = this.options.months;
        0 <= ref ? i < ref : i > ref;
        m = 0 <= ref ? ++i : --i
        ) {
        results.push(this.buildMonth(moment(this.options.start).add(m, 'months')));
      }
      return results;
    })();
  }

  private buildMonth(time: any): { weeks: Array<any>, name: string } {
    let calendarEnd: any, calendarStart: any, start: any, w: any, weeks: any, weeksInMonth: any;
    weeks = [];
    calendarStart = moment(time).startOf('month');
    calendarEnd = moment(time).endOf('month');
    weeksInMonth = 5;
    start = time.startOf('month');
    weeks = (() => {
      let i: any, ref: any, results: any;
      results = [];
      for (
        w = i = 0, ref = weeksInMonth;
        0 <= ref ? i <= ref : i >= ref;
        w = 0 <= ref ? ++i : --i
      ) {
        results.push(this.buildWeek(moment(start).add(w, 'weeks'), moment(start).month()));
      }
      return results;
    })();
    return {
      weeks: weeks,
      name: time.format('MMMM YYYY')
    };
  }

  private buildWeek(time: any, month: any) {
    let days: any, filter: any, start: any;
    days = [];
    filter = true;
    start = time.startOf('isoWeek');
    days = [0, 1, 2, 3, 4, 5, 6].map((d) => {
      let day: any, withinLimits: any, withinMonth: any;
      day = moment(start).add(d, 'days');
      withinMonth = day.month() === month;
      withinLimits = this.withinLimits(day);
      return {
        date: day,
        selected: this.isSelected(day) && withinMonth,
        disabled: !(withinMonth && withinLimits),
        inRange: this.isInRange(day) && withinMonth,
      };
    });
    return days;
  }

  private withinLimits(day: any): boolean {
    let withinLimits: Boolean = true;
    if (this.before) {
      withinLimits = withinLimits && day.isSameOrBefore(this.before, 'day');
    }
    if (this.after) {
      withinLimits = withinLimits && day.isSameOrAfter(this.after, 'day');
    }
    return !!withinLimits;
  }

  private indexOfMoment(array: any, element: any, match: any): number {
    let value: any;
    for (let key = 0; key < array.length; key++) {
      value = array[key];
      if (element.isSame(value, match)) {
        return key;
      }
    }
    return -1;
  }

  private isSelected(day: any): boolean {
    switch (this.options.mode) {
      case 'multiple':
        return this.indexOfMoment(this.date, day, 'day') > -1;
      default:
        return this.date && day.isSame(this.date, 'day');
    }
  }

  private isInRange(day: any): boolean {
    if (Array.isArray(this.date) && this.date.length > 0 && this.date.length === 2) {
      return day.isSameOrAfter(this.date[0]) && day.isSameOrBefore(this.date[1]);
    }
  }
}
