import { Directive, ElementRef, OnInit, Input, HostListener, EventEmitter, Output, AfterContentChecked } from '@angular/core';
// Services
import { HelperService } from 'shared/services/helper.service';

@Directive({
  selector: '[gwSimpleDropdown]',
  exportAs: 'simpleDropdown'
})
export class SimpleDropdownDirective implements OnInit, AfterContentChecked {
  @Input() stopPropagation = false;
  @Input() disabled: boolean;
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  dropdownElement: HTMLElement;
  contentElement: HTMLElement;
  labelElement: HTMLElement;
  openedWithDelay = false;
  opened = false;
  firstDrop = true;
  isHorizontalOverflow = false;
  isVerticalOverflow = false;

  constructor(public element: ElementRef, private helperService: HelperService) { }

  ngOnInit() {
    this.dropdownElement = this.element.nativeElement;
    this.contentElement = this.element.nativeElement.querySelector('.jsDropdownContent');
  }

  ngAfterContentChecked() {
    if (this.openedWithDelay || this.opened) {
      if (!this.isVerticalOverflow) {
        this.isVerticalOverflow = this.isVerticalOverflowWindow();
      }
      if (!this.isHorizontalOverflow) {
        this.isHorizontalOverflow = this.isHorizontalOverflowWindow();
      }
    }
  }

  focusOnDropdown(): void {
    this.element.nativeElement.focus();
  }

  openDropdown(): void {
    if (!this.disabled) {
      this.opened = true;
      this.firstDrop = false;
      this.openedWithDelay = true;
      this.open.emit();
    }
  }

  closeDropdown(): void {
    this.opened = false;
    setTimeout(() => {
      this.openedWithDelay = false;
      this.isHorizontalOverflow = false;
      this.isVerticalOverflow = false;
    }, 300);
    this.close.emit();
  }

  @HostListener('click', ['$event'])
  elementClick(event: any): void {
    if (this.stopPropagation) {
      event.stopPropagation();
    }
    this.labelElement = this.element.nativeElement.querySelector('.jsDropdownLabel');
    if (!(this.contentElement.contains(event.target) || (this.labelElement && this.labelElement.contains(event.target)))) {
      if (this.opened || this.openedWithDelay) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (!this.dropdownElement.contains(event.target) && this.opened) {
      this.closeDropdown();
    }
  }

  isHorizontalOverflowWindow(): boolean {
    const dropdownRightPosition = this.dropdownElement.offsetLeft + this.contentElement.offsetLeft + this.contentElement.clientWidth;
    return dropdownRightPosition > window.innerWidth;
  }

  isVerticalOverflowWindow(): boolean {
    const windowBottomPosition = window.innerHeight + this.helperService.windowScrollY;
    const dropdownBottomPosition = this.dropdownElement.offsetTop + this.contentElement.offsetTop + this.contentElement.clientHeight;
    return dropdownBottomPosition > windowBottomPosition;
  }

  @HostListener('keydown', ['$event'])
  keydown(event: any) {
    switch (event.key) {
      case 'Tab':
        if (this.openedWithDelay) {
          this.closeDropdown();
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (this.openedWithDelay) {
          this.closeDropdown();
        } else {
          this.openDropdown();
        }
        return false;
    }
  }
}
