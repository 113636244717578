import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { TileHeight } from 'shared/components/tiles/tiles.component';
import { RiskType } from 'shared/enums/risk-type.enum';
import { Tile } from 'shared/interfaces/tile.interface';
import { AlertService } from 'shared/services/alert.service';
import { NearMissStepperService } from 'shared/services/near-miss-stepper.service';
import { NearMissService } from 'shared/services/near-miss.service';

@Component({
  selector: 'gw-near-miss-step4',
  templateUrl: './near-miss-step4.component.pug',
  styleUrls: ['./near-miss-step4.component.scss']
})

export class NearMissStep4Component implements OnInit {
  translation = {
    risk: {
      low: '',
      medium: '',
      increased: '',
      high: ''
    }
  };
  tiles: Array<Tile>;
  tileHeights = TileHeight;
  riskForm: FormGroup;
  submitted = false;
  isSaving: boolean;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private nearMissService: NearMissService,
    private nearMissStepperService: NearMissStepperService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  async ngOnInit() {
    await this.fetchTranslation();
    this.createTiles();
    this.initForm();
  }

  private initForm(): void {
    const stepData = this.nearMissStepperService.formSteps.step4;
    this.riskForm = this.formBuilder.group({
      risk: [stepData && stepData.risk || '', Validators.required]
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  private createTiles(): void {
    this.tiles = [
      {
        id: RiskType.low,
        imageUrl: '/assets/icons/warning-green.svg',
        translationString: this.translation.risk.low
      },
      {
        id: RiskType.medium,
        imageUrl: '/assets/icons/warning-yellow.svg',
        translationString: this.translation.risk.medium
      },
      {
        id: RiskType.increased,
        imageUrl: '/assets/icons/warning-orange.svg',
        translationString: this.translation.risk.increased
      },
      {
        id: RiskType.high,
        imageUrl: '/assets/icons/warning-red.svg',
        translationString: this.translation.risk.high
      },
    ];
  }

  toggleTile(tile: Tile): void {
    if (tile.id !== this.riskForm.controls.risk.value) {
      this.riskForm.controls.risk.setValue(tile.id);
    }
  }

  async submitForm(form: FormGroup): Promise<void> {
    this.submitted = true;

    if (form.valid) {
      this.isSaving = true;
      await this.updateStep(form);
      this.nearMissService.reportNearMiss(
        this.nearMissStepperService.getFormData()
      )
        .then(() => {
          this.alertService.success();
          this.router.navigate(['/esh']);
        })
        .catch(() => {
          this.alertService.error();
          this.isSaving = false;
        });
    }
  }

  updateStep(form: FormGroup): void {
    this.nearMissStepperService.formSteps.step4 = this.nearMissStepperService.convertStep4FormToObject(form);
    this.nearMissStepperService.updateInStorage();
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'GLOBAL.RISK.LOW',
      'GLOBAL.RISK.MEDIUM',
      'GLOBAL.RISK.INCREASED',
      'GLOBAL.RISK.HIGH',
    ]).toPromise();
    this.translation.risk.low = translation['GLOBAL.RISK.LOW'];
    this.translation.risk.medium = translation['GLOBAL.RISK.MEDIUM'];
    this.translation.risk.increased = translation['GLOBAL.RISK.INCREASED'];
    this.translation.risk.high = translation['GLOBAL.RISK.HIGH'];
  }
}
