import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import moment from 'moment';
import { Attachment } from 'shared/models/attachment.model';
import { Employee } from 'shared/models/employee.model';
import { EnvironmentalIncident } from 'shared/models/environmental-incident.model';
// Services
import { AttachmentService } from 'shared/services/attachment.service';
import { FileRawObject } from 'shared/services/file.service';
import { FormNames, StepperService } from 'shared/services/stepper.service';
import { StorageService } from 'shared/services/storage.service';

export class IncidentSteps {
  constructor(
    public step1?: IncidentStep1,
    public step2?: IncidentStep2,
    public step3?: IncidentStep3,
    public step4?: IncidentStep4
  ) { }
}

export interface IncidentStep1 {
  date: moment.Moment;
  location: string;
  reportedBy: Employee;
}

export interface IncidentStep2 {
  environmentalAccidentType: any;
  remark: string;
}

export interface IncidentStep3 {
  descriptionIncident: string;
  descriptionMeasure: string;
  attachments?: Array<Attachment>;
}

export interface IncidentStep3Storage {
  descriptionIncident: string;
  descriptionMeasure: string;
  attachments?: Array<FileRawObject>;
}

export interface IncidentStep4 {
  damages: boolean;
  damagesDescription?: string;
}

export class IncidentStepsStorage {
  constructor(
    public step1?: IncidentStep1,
    public step2?: IncidentStep2,
    public step3?: IncidentStep3Storage,
    public step4?: IncidentStep4
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentalIncidentStepperService extends StepperService {
  formSteps: IncidentSteps;
  formName = FormNames.environmentalIncidentForm;

  constructor(
    private attachmentService: AttachmentService,
    protected storageService: StorageService
  ) {
    super(storageService);
    this.formSteps = this.getFromStorage() || new IncidentSteps();
  }

  getFromStorage(): IncidentSteps {
    const incidentStorage: IncidentStepsStorage = super.getFromStorage();
    const incidentSteps = new IncidentSteps();
    if (incidentStorage) {
      incidentSteps.step1 = { ...incidentStorage.step1 };
      incidentSteps.step2 = { ...incidentStorage.step2 };
      incidentSteps.step3 = {
        descriptionIncident: incidentStorage.step3 && incidentStorage.step3.descriptionIncident,
        descriptionMeasure: incidentStorage.step3 && incidentStorage.step3.descriptionMeasure,
        attachments: incidentStorage.step3 && this.attachmentService.convertAllToAttachments(incidentStorage.step3.attachments)
      };
      incidentSteps.step4 = { ...incidentStorage.step4 };
    }
    return incidentSteps;
  }

  removeFromStorage(): void {
    this.formSteps = new IncidentSteps();
    return super.removeFromStorage();
  }

  async updateInStorage(): Promise<void> {
    const incidentStorage = new IncidentStepsStorage();
    incidentStorage.step1 = { ...this.formSteps.step1 };
    incidentStorage.step2 = { ...this.formSteps.step2 };
    incidentStorage.step3 = {
      descriptionIncident: this.formSteps.step3 && this.formSteps.step3.descriptionIncident,
      descriptionMeasure: this.formSteps.step3 && this.formSteps.step3.descriptionMeasure,
      attachments: this.formSteps.step3 && await this.attachmentService.convertAllToRawObjects(this.formSteps.step3.attachments)
    };
    incidentStorage.step4 = { ...this.formSteps.step4 };

    super.updateInStorage(incidentStorage);
  }

  getFormData(): EnvironmentalIncident {
    return {
      ...this.formSteps.step1,
      ...this.formSteps.step2,
      ...this.formSteps.step3,
      ...this.formSteps.step4
    };
  }

  clearSteps() {
    this.formSteps = new IncidentSteps();
  }

  convertStep1FormToObject(form: FormGroup): IncidentStep1 {
    return {
      date: form.value.date,
      location: form.value.location,
      reportedBy: form.value.reportedBy
    };
  }

  convertStep2FormToObject(form: FormGroup): IncidentStep2 {
    return {
      environmentalAccidentType: form.value.type,
      remark: form.value.remark
    };
  }

  convertStep3FormToObject(form: FormGroup): IncidentStep3 {
    return {
      attachments: form.value.attachments,
      descriptionIncident: form.value.descriptionIncident,
      descriptionMeasure: form.value.descriptionMeasure
    };
  }

  convertStep4FormToObject(form: FormGroup): IncidentStep4 {
    return {
      damages: form.value.damages === 'Yes',
      damagesDescription: form.value.description,
    };
  }
}
