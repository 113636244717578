import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiUrl } from 'shared/enums/api-url.enum';
import { ApiEmployee } from 'shared/interfaces/employee.interface';
import { Employee } from 'shared/models/employee.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private http: HttpClient) { }
  defaultEmployee: Employee;

  getEmployees(): Promise<Array<Employee>> {
    return this.http.get(`${ApiUrl.icmApi}/entity/list`).toPromise()
      .then((employees: Array<ApiEmployee>) => {
        this.defaultEmployee = this.convertEmployeeToGet(employees[0]);
        return employees.map(employee => this.convertEmployeeToGet(employee));
      });
  }

  getDefaultEmployee(): Employee {
    return this.defaultEmployee;
  }

  convertEmployeeToGet(employee: ApiEmployee): Employee {
    return {
      id: employee.ID,
      name: employee.Name
    };
  }
}
