import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EhsComponent } from 'ehs/ehs.component';
import { AccidentStep1Component } from 'ehs/views/accident/accident-step1/accident-step1.component';
import { AccidentStep2Component } from 'ehs/views/accident/accident-step2/accident-step2.component';
import { AccidentStep3Component } from 'ehs/views/accident/accident-step3/accident-step3.component';
// Components
import { AccidentComponent } from 'ehs/views/accident/accident.component';
import { EnvironmentalIncidentStep1Component } from 'ehs/views/environmental-incident/environmental-incident-step1/environmental-incident-step1.component';
import { EnvironmentalIncidentStep2Component } from 'ehs/views/environmental-incident/environmental-incident-step2/environmental-incident-step2.component';
import { EnvironmentalIncidentStep3Component } from 'ehs/views/environmental-incident/environmental-incident-step3/environmental-incident-step3.component';
import { EnvironmentalIncidentStep4Component } from 'ehs/views/environmental-incident/environmental-incident-step4/environmental-incident-step4.component';
import { EnvironmentalIncidentComponent } from 'ehs/views/environmental-incident/environmental-incident.component';
import { LoginPageComponent } from 'ehs/views/login-page/login-page.component';
import { MenuComponent } from 'ehs/views/menu/menu.component';
import { NearMissStep1Component } from 'ehs/views/near-miss/near-miss-step1/near-miss-step1.component';
import { NearMissStep2Component } from 'ehs/views/near-miss/near-miss-step2/near-miss-step2.component';
import { NearMissStep3Component } from 'ehs/views/near-miss/near-miss-step3/near-miss-step3.component';
import { NearMissStep4Component } from 'ehs/views/near-miss/near-miss-step4/near-miss-step4.component';
import { NearMissComponent } from 'ehs/views/near-miss/near-miss.component';
import { UnsafeConditionStep1Component } from 'ehs/views/unsafe-condition/unsafe-condition-step1/unsafe-condition-step1.component';
import { UnsafeConditionStep2Component } from 'ehs/views/unsafe-condition/unsafe-condition-step2/unsafe-condition-step2.component';
import { UnsafeConditionStep3Component } from 'ehs/views/unsafe-condition/unsafe-condition-step3/unsafe-condition-step3.component';
import { UnsafeConditionComponent } from 'ehs/views/unsafe-condition/unsafe-condition.component';
// Guards
import { LoginGuard } from 'shared/guards/login.guard';
import { LogoutGuard } from 'shared/guards/logout.guard';
// Resolvers
import { BodyPartsResolve } from 'shared/resolvers/body-parts.resolve';
import { EmployeesResolve } from 'shared/resolvers/employees.resolve';
import { InjuryTypesResolve } from 'shared/resolvers/injury-types.resolve';

const ehsRoutes: Routes = [
  {
    path: 'ehs',
    component: EhsComponent,
    children: [
      {
        path: '',
        component: MenuComponent,
        canActivate: [
          LoginGuard
        ],
        children: []
      },
      {
        path: 'login',
        component: LoginPageComponent,
        canActivate: [
          LogoutGuard
        ]
      },
      {
        path: 'accident',
        component: AccidentComponent,
        canActivate: [
          LoginGuard
        ],
        children: [
          { path: '', redirectTo: 'data', pathMatch: 'full' },
          {
            path: 'data',
            component: AccidentStep1Component,
            resolve: {
              employees: EmployeesResolve
            }
          },
          {
            path: 'description',
            component: AccidentStep2Component,
            resolve: {
              bodyParts: BodyPartsResolve,
              injuryTypes: InjuryTypesResolve
            }
          },
          { path: 'measures', component: AccidentStep3Component },
          { path: '**', redirectTo: 'data', pathMatch: 'full' }
        ]
      },
      {
        path: 'near-miss',
        component: NearMissComponent,
        canActivate: [
          LoginGuard
        ],
        children: [
          { path: '', redirectTo: 'data', pathMatch: 'full' },
          {
            path: 'data',
            component: NearMissStep1Component,
            resolve: {
              employees: EmployeesResolve
            }
          },
          { path: 'description', component: NearMissStep2Component },
          { path: 'material-damage', component: NearMissStep3Component },
          { path: 'risk', component: NearMissStep4Component },
          { path: '**', redirectTo: 'data', pathMatch: 'full' }
        ]
      },
      {
        path: 'unsafe-condition',
        component: UnsafeConditionComponent,
        canActivate: [
          LoginGuard
        ],
        children: [
          { path: '', redirectTo: 'data', pathMatch: 'full' },
          {
            path: 'data',
            component: UnsafeConditionStep1Component,
            resolve: {
              employees: EmployeesResolve
            }
          },
          { path: 'description', component: UnsafeConditionStep2Component },
          { path: 'risk', component: UnsafeConditionStep3Component },
          { path: '**', redirectTo: 'data', pathMatch: 'full' }
        ]
      },
      {
        path: 'environmental-incident',
        component: EnvironmentalIncidentComponent,
        canActivate: [
          LoginGuard
        ],
        children: [
          { path: '', redirectTo: 'data', pathMatch: 'full' },
          {
            path: 'data',
            component: EnvironmentalIncidentStep1Component,
            resolve: {
              employees: EmployeesResolve
            }
          },
          { path: 'incident-type', component: EnvironmentalIncidentStep2Component },
          { path: 'description', component: EnvironmentalIncidentStep3Component },
          { path: 'material-damage', component: EnvironmentalIncidentStep4Component },
          { path: '**', redirectTo: 'data', pathMatch: 'full' }
        ]
      },
      { path: '**',
        redirectTo: '',
        pathMatch: 'full',
        canActivate: [
          LoginGuard
        ],
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(ehsRoutes)],
  exports: [RouterModule]
})
export class EhsRoutingModule {}
