import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-near-miss',
  templateUrl: './near-miss.component.pug',
  styleUrls: ['./near-miss.component.scss']
})
export class NearMissComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
