import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  translation = {
    error: {
      login: {
        title: '',
        description: ''
      },
      report: {
        title: '',
        description: ''
      }
    },
    success: {
      login: {
        title: '',
        description: ''
      },
      report: {
        title: '',
        description: ''
      }
    }
  };

  constructor(
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    this.fetchTranslation();
  }

  success(): void {
    this.notificationsService.success(this.translation.success.report.title, this.translation.success.report.description);
  }

  error(type: string = ''): void {
    switch (type) {
      case 'login':
        this.notificationsService.error(this.translation.error.login.title, this.translation.error.login.description);
        break;
      default:
        this.notificationsService.error(this.translation.error.report.title, this.translation.error.report.description);
        break;
    }
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'GLOBAL.ALERT.ERROR_LOGIN',
      'GLOBAL.ALERT.ERROR_LOGIN_DESCRIPTION',
      'GLOBAL.ALERT.ERROR_REPORT',
      'GLOBAL.ALERT.ERROR_REPORT_DESCRIPTION',
      'GLOBAL.ALERT.SUCCESS_REPORT',
      'GLOBAL.ALERT.SUCCESS_REPORT_DESCRIPTION'
    ]).toPromise();
    this.translation.error.login.title = translation['GLOBAL.ALERT.ERROR_LOGIN'];
    this.translation.error.login.description = translation['GLOBAL.ALERT.ERROR_LOGIN_DESCRIPTION'];
    this.translation.error.report.title = translation['GLOBAL.ALERT.ERROR_REPORT'];
    this.translation.error.report.description = translation['GLOBAL.ALERT.ERROR_REPORT_DESCRIPTION'];
    this.translation.success.report.title = translation['GLOBAL.ALERT.SUCCESS_REPORT'];
    this.translation.success.report.description = translation['GLOBAL.ALERT.SUCCESS_REPORT_DESCRIPTION'];
  }
}
