import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
// Components
import { TileHeight } from 'shared/components/tiles/tiles.component';
// Interfaces
import { Tile } from 'shared/interfaces/tile.interface';
// Services
import { EnvironmentalIncidentStepperService } from 'shared/services/environmental-incident-stepper.service';

export enum IncidentTypes {
  fire = 'Fire',
  chemicalLeak = 'ChemicalLeak',
  contaminationSoilWater = 'ContaminationSoilWater',
  other = 'Other'
}

@Component({
  selector: 'gw-environmental-incident-step2',
  templateUrl: './environmental-incident-step2.component.pug',
  styleUrls: ['./environmental-incident-step2.component.scss']
})
export class EnvironmentalIncidentStep2Component implements OnInit {
  translation = {
    types: {
      fire: '',
      chemicalLeak: '',
      contaminationSoilWater: '',
      other: ''
    }
  };
  tiles: Array<Tile>;
  typesForm: FormGroup;
  submitted = false;
  tileHeights = TileHeight;

  constructor(
    private incidentStepperService: EnvironmentalIncidentStepperService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private translateService: TranslateService
  ) { }

  async ngOnInit() {
    await this.fetchTranslation();
    this.createTiles();
    this.initForm();
  }

  private initForm(): void {
    const dataStep2 = this.incidentStepperService.formSteps.step2;

    this.typesForm = this.formBuilder.group({
      type: [dataStep2 && dataStep2.environmentalAccidentType || '', Validators.required]
    });

    if (dataStep2 && dataStep2.remark) {
      this.addRemarkControl(dataStep2.remark);
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  private createTiles(): void {
    this.tiles = [
      {
        id: IncidentTypes.fire,
        svgUrl: '/assets/icons/incident-types.svg#fire',
        translationString: this.translation.types.fire,
        smallIcon: true
      },
      {
        id: IncidentTypes.chemicalLeak,
        svgUrl: '/assets/icons/incident-types.svg#laboratory',
        translationString: this.translation.types.chemicalLeak,
        smallIcon: true
      },
      {
        id: IncidentTypes.contaminationSoilWater,
        svgUrl: '/assets/icons/incident-types.svg#oil',
        translationString: this.translation.types.contaminationSoilWater,
        smallIcon: true
      },
      {
        id: IncidentTypes.other,
        svgUrl: '/assets/icons/sprite-measures.svg#more',
        translationString: this.translation.types.other,
        smallIcon: true
      },
    ];
  }

  toggleTile(tile: Tile): void {
    if (this.typesForm.controls.type.value !== tile.id) {
      this.typesForm.controls.type.setValue(tile.id);

      if (tile.id === IncidentTypes.other) {
        this.addRemarkControl('');
      } else {
        this.removeRemarkControl();
      }
    }
  }

  addRemarkControl(value: string): void {
    this.typesForm.addControl('remark', new FormControl(value, Validators.required));
    this.changeDetector.detectChanges();
  }

  removeRemarkControl(): void {
    this.typesForm.removeControl('remark');
  }

  submitForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateStep(form);
      this.goToNextStep();
    }
  }

  updateStep(form: FormGroup): void {
    this.incidentStepperService.formSteps.step2 = this.incidentStepperService.convertStep2FormToObject(form);
    this.incidentStepperService.updateInStorage();
  }

  goToNextStep(): void {
    this.router.navigate(['/ehs/environmental-incident/description'], { replaceUrl: true });
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'EHS.ENVIRONMENTAL_INCIDENT.STEP_2.INCIDENT_TYPES.FIRE',
      'EHS.ENVIRONMENTAL_INCIDENT.STEP_2.INCIDENT_TYPES.CHEMICAL_LEAK',
      'EHS.ENVIRONMENTAL_INCIDENT.STEP_2.INCIDENT_TYPES.CONTAMINATION_SOIL_WATER',
      'EHS.ENVIRONMENTAL_INCIDENT.STEP_2.INCIDENT_TYPES.OTHER',
    ]).toPromise();
    this.translation.types.fire = translation['EHS.ENVIRONMENTAL_INCIDENT.STEP_2.INCIDENT_TYPES.FIRE'];
    this.translation.types.chemicalLeak = translation['EHS.ENVIRONMENTAL_INCIDENT.STEP_2.INCIDENT_TYPES.CHEMICAL_LEAK'];
    this.translation.types.contaminationSoilWater = translation['EHS.ENVIRONMENTAL_INCIDENT.STEP_2.INCIDENT_TYPES.CONTAMINATION_SOIL_WATER'];
    this.translation.types.other = translation['EHS.ENVIRONMENTAL_INCIDENT.STEP_2.INCIDENT_TYPES.OTHER'];
  }
}
