import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Models
import { Attachment } from 'shared/models/attachment.model';
// Services
import { EnvironmentalIncidentStepperService } from 'shared/services/environmental-incident-stepper.service';

@Component({
  selector: 'gw-environmental-incident-step3',
  templateUrl: './environmental-incident-step3.component.pug',
  styleUrls: ['./environmental-incident-step3.component.scss']
})
export class EnvironmentalIncidentStep3Component implements OnInit {
  descriptionForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private incidentStepperService: EnvironmentalIncidentStepperService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initForm();
    this.changeDetector.detectChanges();
  }

  private initForm(): void {
    const stepData = this.incidentStepperService.formSteps.step3;
    this.descriptionForm = this.formBuilder.group({
      descriptionIncident: [(stepData && stepData.descriptionIncident) || '', Validators.required],
      descriptionMeasure: [(stepData && stepData.descriptionMeasure) || '', Validators.required],
      attachments: [(stepData && stepData.attachments) || [], ]
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  updateAtachments(attachments: Array<Attachment>): void {
    this.descriptionForm.controls.attachments.setValue(attachments);
  }

  submitForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateStep(form);
      this.goToNextStep();
    }
  }

  async updateStep(form: FormGroup): Promise<void> {
    this.incidentStepperService.formSteps.step3 = this.incidentStepperService.convertStep3FormToObject(form);
    await this.incidentStepperService.updateInStorage();
  }

  goToNextStep(): void {
    this.router.navigate(['/ehs/environmental-incident/material-damage'], { replaceUrl: true });
  }
}
