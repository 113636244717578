import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import moment from 'moment';
import { ApiUrl } from 'shared/enums/api-url.enum';
import { ApiEnvironmentalIncident } from 'shared/interfaces/environmental-incident.interface';
import { Attachment } from 'shared/models/attachment.model';
import { EnvironmentalIncident } from 'shared/models/environmental-incident.model';

@Injectable({
  providedIn: 'root'
})

export class EnvironmentalIncidentService {
  environmentalIncidentID: number;

  constructor(
    private http: HttpClient
  ) { }

  addReport(environmentalIncident: EnvironmentalIncident): Promise<void> {
    return this.http.post(
      `${ApiUrl.icmApi}/continuousimprovementprocess`,
      this.convertReportedEnvironmentalIncidentToPost(environmentalIncident)
    ).toPromise()
      .then((environmentalIncidentID: number) => {
        this.environmentalIncidentID = environmentalIncidentID;
      })
      .catch(async error => {
        throw error;
      });
  }

  addAttachment(payload: { id: number, attachment: Attachment }): Promise<Object> {
    const formData: FormData = new FormData();
    let params: HttpParams = new HttpParams();
    formData.append('attachment', payload.attachment.file);
    params = params.append('continuousImprovmentProcessID', payload.id.toString());

    return this.http.post(`${ApiUrl.icmApi}/continuousimprovementprocess/addAttachment`, formData, { params }).toPromise()
    .catch((error) => {
        throw error;
      });
  }

  addAllAttachments(attachments: Array<Attachment>): Promise<Array<Attachment>> {
    const attachmentsPromises = [];

    attachments.forEach(attachment => {
      attachmentsPromises.push(this.addAttachment({ id: this.environmentalIncidentID, attachment }));
    });

    return Promise.all(attachmentsPromises)
      .catch((error) => {
        throw error;
      });
  }

  async reportEnvironmentalIncident(environmentalIncident: EnvironmentalIncident): Promise<void> {
    await this.addReport(environmentalIncident);

    if (environmentalIncident.attachments) {
      await this.addAllAttachments(environmentalIncident.attachments);
    }
  }

  convertReportedEnvironmentalIncidentToPost(environmentalIncident: EnvironmentalIncident): ApiEnvironmentalIncident {
    return {
      Type: 'EnvironmentalIncident',
      Date: moment(environmentalIncident.date).format(),
      Location: environmentalIncident.location,
      ReportEmployeeID: environmentalIncident.reportedBy.id,
      EnvironmentalAccidentType: environmentalIncident.environmentalAccidentType,
      Remark: environmentalIncident.remark,
      Description: environmentalIncident.descriptionIncident,
      ProposedSolution: environmentalIncident.descriptionMeasure,
      MaterialDamage: environmentalIncident.damages,
      MaterialDamageDescription: environmentalIncident.damagesDescription,
      PotentialRiskLevel: 0 // TODO: Remove after change BE model
    };
  }
}
