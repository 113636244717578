import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-environmental-incident',
  templateUrl: './environmental-incident.component.pug',
  styleUrls: ['./environmental-incident.component.scss']
})
export class EnvironmentalIncidentComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
