export class UserProfile {
  username: string;
  firstname: string;
  lastname: string;
  language: string;

  constructor(userProfile: UserProfile) {
    this.username = userProfile.username;
    this.firstname = userProfile.firstname;
    this.lastname = userProfile.lastname;
    this.language = userProfile.language;
  }
}
