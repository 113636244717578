import { Injectable } from '@angular/core';
// Services
import { StorageService } from 'shared/services/storage.service';

export enum FormNames {
  accidentForm = 'AccidentForm',
  nearMissForm = 'NearMissForm',
  unsaveConditionForm = 'UnsaveConditionForm',
  environmentalIncidentForm = 'EnvironmentalIncidentForm',
}

@Injectable({
  providedIn: 'root'
})
export class StepperService {
  formName: string;
  formSteps: any;

  constructor(protected storageService: StorageService) { }

  getFromStorage(): any {
    return this.storageService.getStorage(this.formName);
  }

  updateInStorage(formSteps = this.formSteps): void {
    this.storageService.setStorage(this.formName, formSteps);
  }

  removeFromStorage(): void {
    this.storageService.removeFromStorage(this.formName);
  }
}
