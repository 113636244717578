import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthenticationService } from 'shared/services/authentication.service';

@Injectable()
export class LogoutGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (!this.authenticationService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }

}
