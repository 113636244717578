import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import moment from 'moment';
import { Attachment } from 'shared/models/attachment.model';
import { Employee } from 'shared/models/employee.model';
import { NearMiss } from 'shared/models/near-miss.model';
// Services
import { AttachmentService } from 'shared/services/attachment.service';
import { FileRawObject } from 'shared/services/file.service';
import { FormNames, StepperService } from 'shared/services/stepper.service';
import { StorageService } from 'shared/services/storage.service';

export class NearMissSteps {
  constructor(
    public step1?: NearMissStep1,
    public step2?: NearMissStep2,
    public step3?: NearMissStep3,
    public step4?: NearMissStep4
  ) { }
}

export interface NearMissStep1 {
  date: moment.Moment;
  location: string;
  reportedBy: Employee;
  witnesses?: Employee[];
}

export interface NearMissStep2 {
  description: string;
  attachments: Array<Attachment>;
}

export interface NearMissStep2Storage {
  description: string;
  attachments: Array<FileRawObject>;
}

export interface NearMissStep3 {
  damages: string;
  damageDescription?: string;
}

export interface NearMissStep4 {
  risk: string;
}

export class NearMissStepsStorage {
  constructor(
    public step1?: NearMissStep1,
    public step2?: NearMissStep2Storage,
    public step3?: NearMissStep3,
    public step4?: NearMissStep4
  ) { }
}

@Injectable({
  providedIn: 'root'
})

export class NearMissStepperService extends StepperService {
  formSteps: NearMissSteps;
  formName = FormNames.nearMissForm;

  constructor(
    protected storageService: StorageService,
    private attachmentService: AttachmentService
  ) {
    super(storageService);
    this.formSteps = this.getFromStorage() || new NearMissSteps();
  }

  getFromStorage(): NearMissSteps {
    const nearMissStorage: NearMissStepsStorage = super.getFromStorage();
    const nearMissSteps = new NearMissSteps();
    if (nearMissStorage) {
      nearMissSteps.step1 = { ...nearMissStorage.step1 };
      nearMissSteps.step2 = {
        description: nearMissStorage.step2 && nearMissStorage.step2.description,
        attachments: nearMissStorage.step2 && this.attachmentService.convertAllToAttachments(nearMissStorage.step2.attachments)
      };
      nearMissSteps.step3 = { ...nearMissStorage.step3 };
      nearMissSteps.step4 = { ...nearMissStorage.step4 };
    }
    return nearMissSteps;
  }

  getFormData(): NearMiss {
    return {
      ...this.formSteps.step1,
      ...this.formSteps.step2,
      ...this.formSteps.step3,
      ...this.formSteps.step4
    };
  }

  removeFromStorage(): void {
    this.formSteps = new NearMissSteps();
    return super.removeFromStorage();
  }

  async updateInStorage(): Promise<void> {
    const nearMissStorage = new NearMissStepsStorage();
    nearMissStorage.step1 = { ...this.formSteps.step1 };
    nearMissStorage.step2 = {
    description: this.formSteps.step2 && this.formSteps.step2.description || '',
    attachments: this.formSteps.step2
      && await this.attachmentService.convertAllToRawObjects(this.formSteps.step2.attachments) || []
    };
    nearMissStorage.step3 = { ...this.formSteps.step3 };
    nearMissStorage.step4 = { ...this.formSteps.step4 };

    super.updateInStorage(nearMissStorage);
  }

  clearSteps() {
    this.formSteps = new NearMissSteps();
  }

  convertStep1FormToObject(form: FormGroup): NearMissStep1 {
    return {
      date: form.value.date,
      location: form.value.location,
      reportedBy: form.value.reportedBy,
      witnesses: form.value.witnesses
    };
  }

  convertStep2FormToObject(form: FormGroup): NearMissStep2 {
    return {
      description: form.value.description,
      attachments: form.value.attachments
    };
  }

  convertStep3FormToObject(form: FormGroup): NearMissStep3 {
    return {
      damages: form.value.damages,
      damageDescription: form.value.description
    };
  }

  convertStep4FormToObject(form: FormGroup): NearMissStep4 {
    return {
      risk: form.value.risk,
    };
  }
}
