import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'gw-date-time-picker',
  templateUrl: './date-time-picker.component.pug',
  styleUrls: ['./date-time-picker.component.scss'],
  exportAs: 'gwDateTimePicker'
})
export class DateTimePickerComponent implements OnInit {
  @Input() date: string;
  @Input() options: any;
  @Input() label: string;
  @Output() applyDate = new EventEmitter<any>();
  momentDate: moment.Moment;
  hour: string;
  minute: string;
  cachedMinute: string;
  cachedHour: string;
  pressed = false;

  constructor() { }

  ngOnInit() {
    if (!this.date) {
      this.momentDate = moment();
    } else {
      this.momentDate = moment(this.date);
    }
    this.hour = moment(this.momentDate).format('HH');
    this.minute = moment(this.momentDate).format('mm');
  }

  addLeadingZero(value: string): string {
    if (value && value.length === 1) {
      return '0' + value;
    } else {
      return value;
    }
  }

  checkMaxTime(value: string, max: number): string {
    if (parseInt(value, 10) > max) {
      value = max.toString();
    }
    value = this.addLeadingZero(value);
    return value;
  }

  onHourBlur(hour: string): void {
    hour = hour || this.cachedHour;
    this.hour = this.checkMaxTime(hour, 23);
    this.setDate(this.momentDate);
  }

  onMinuteBlur(minute: string): void {
    minute = minute || this.cachedMinute;
    this.minute =  this.checkMaxTime(minute, 59);
    this.setDate(this.momentDate);
  }

  onFocus(): string {
    this.cachedHour = this.hour;
    this.cachedMinute = this.minute;
    return '';
  }

  onKeyPress(event: KeyboardEvent, value: string): void {
    this.pressed = true;
    const pattern = /[0-9]+/;
    if (value && value.length === 2 || !pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  joinDateAndTime(date, hour, minute): moment.Moment {
    return moment(date.hour(parseInt(hour, 10)).minute(parseInt(minute, 10)));
  }

  setDate(date: any): void {
    this.momentDate = this.joinDateAndTime(date, this.hour, this.minute);
  }

  applyDateTime(): void {
    const date = this.joinDateAndTime(this.momentDate, this.hour, this.minute);
    this.applyDate.emit(date.format('YYYY-MM-DD HH:mm:ss'));
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    switch (event.key) {
      case 'Tab':
        event.preventDefault();
        return false;
      case 'Enter':
        event.preventDefault();
        this.applyDateTime();
        return false;
    }
  }
}
