import { Injectable, ElementRef } from '@angular/core';
import * as Crypto from 'crypto-js';
// Models
import { Attachment } from 'shared/models/attachment.model';

export interface FileRawObject {
  name?: string;
  base64: string;
  contentType: string;
  hash: string;
  rotation: number;
}

@Injectable()
export class FileService {
  imageType = /^image\//;

  constructor() { }

  attachFiles(event: Event, returnAttachments = false, callback?: Function): void {
    const fileList: FileList = event.target['files'];
    const uploadedFiles = [];
    let fileCount = 0;

    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        fileCount++;
        const file: File = fileList[i];
        if (returnAttachments) {
          uploadedFiles.push(new Attachment({ file }));
          if (fileList.length === fileCount) {
            callback(uploadedFiles);
          }
        } else {
          this.generateImagePreview(file).then(image => {
            uploadedFiles.push(image);
            if (fileList.length === fileCount) {
              callback(uploadedFiles);
            }
          });
        }
      }
    }
  }

  generateImagePreview(file: Blob): Promise<string> {
    const reader = new FileReader();
    const imageTag = document.createElement;

    return new Promise(resolve => {
      if (this.imageType.test(file.type)) {
        reader.onload = (img => {
          return (element) => {
            resolve(element.target.result);
          };
        })(imageTag);

        reader.readAsDataURL(file);
      } else {
        resolve();
      }
    });
  }

  readFile(file: Blob): Promise<FileRawObject> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = (img => {
        return (element) => {
          const base64 = generateBase64(element);
          const hash = generateHashSha256(element);
          const rotation = this.imageType.test(file.type) ? getRotation(element) : undefined;
          resolve({
            base64,
            contentType: file.type,
            hash,
            rotation
          });
        };
      })(file);

      reader.readAsArrayBuffer(file);
    });

    function generateBase64(element: {target: {result: any}}): string {
      let binary = '';
      const bytes = new Uint8Array(element.target.result);
      for (let i = 0; i < bytes.byteLength; i++) {
          binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa(binary);
    }

    function generateHashSha256(element: {target: {result: any}}): string {
      const arrayBuffer = element.target.result;
      const wordArray = arrayBufferToWordArray(arrayBuffer);
      const hash = Crypto.SHA256(wordArray);
      return hash.toString();
    }

    function arrayBufferToWordArray(arrayBuffer: any): any {
      const i8a = new Uint8Array(arrayBuffer);
      const a = [];
      for (let i = 0; i < i8a.length; i += 4) {
        a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
      }
      return Crypto.lib.WordArray.create(a, i8a.length);
    }

    function getRotation(element: any): number {
      let rotation = -1;
      const view = new DataView(element.target.result);
      if (view.getUint16(0, false) !== 0xFFD8) {
        rotation = -2;
      }
      const length = view.byteLength;
      let offset = 2;
      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xFFE1) {
          if (view.getUint32(offset += 2, false) !== 0x45786966) {
            rotation = -1;
          }
          const little = view.getUint16(offset += 6, false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) === 0x0112) {
              rotation = view.getUint16(offset + (i * 12) + 8, little);
            }
          }
        } else if ((marker & 0xFF00) !== 0xFF00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return translateRotation(rotation);
    }

    function translateRotation(rotation: number): number {
      switch (rotation) {
        case 1:
          return 0;
        case 2:
          return 0;
        case 3:
          return 180;
        case 4:
          return 180;
        case 5:
          return 90;
        case 6:
          return 90;
        case 7:
          return 270;
        case 8:
          return 270;
        default:
          return 0;
      }
    }
  }

  base64ToBlob(base64: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  isDuplicated(checkedAttachment: Attachment, attachments: Array<Attachment>): boolean {
    return !!attachments.find(attachment => !attachment.toRemove && checkedAttachment.checksum === attachment.checksum);
  }

  resetFileInput(inputRef: ElementRef): void {
    if (inputRef) {
      const input = inputRef.nativeElement;
      input.value = '';
      if (!/safari/i.test(navigator.userAgent)) {
        input.type = '';
        input.type = 'file';
      }
    }
  }
}
