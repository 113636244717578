import { environment } from 'environments/environment';

class ApiUrlClass {
  api = `${environment.api}`;
  authApi = `${this.api}/apis/authenticate`;
  userApi = `${this.api}/apis/user`;
  oamApi = `${this.api}/apis/data/oam`;
  icmApi = `${this.api}/apis/data/icm`;
}

export const ApiUrl = new ApiUrlClass();
