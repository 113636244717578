import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, ViewChild, HostListener, ElementRef, ViewChildren, QueryList } from '@angular/core';

import * as _ from 'lodash';

// Directives
import { SimpleDropdownDirective } from 'shared/directives/simple-dropdown.directive';


@Component({
  selector: 'gw-multiselect',
  templateUrl: './multiselect.component.pug',
  styleUrls: ['./multiselect.component.scss'],
  exportAs: 'gwMultiselect'
})
export class MultiselectComponent implements OnInit, OnChanges {
  @ViewChild('selectDropdown') selectDropdown: SimpleDropdownDirective;
  @ViewChild('scrollView') scrollView: ElementRef;
  @ViewChildren('itemElement') itemsElements: QueryList<ElementRef>;
  @Input() data: Array<any>;
  @Input() display: Array<string>;
  @Input() selected: Array<any> = this.selected || [];
  @Input() uniqueKey: string;
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() disabled: boolean;
  @Input() splitter = ' ';
  @Input() separator = ', ';
  @Input() small = false;
  @Output() output = new EventEmitter<any>();
  searchQuery: string;
  filteredData: Array<any> = [];
  focusedItem: any;

  constructor() { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init(): void {
    if (this.data) {
      this.filterItem('', this.data);
      this.checkSelected();
    }
  }

  checkSelected(): void {
    this.data.map((item) => {
      delete item.selected;
    });
    if (this.selected.length && this.data.length) {
      for (const elementSelected of this.selected) {
        for (const element of this.data) {
          if (elementSelected[this.uniqueKey] === element[this.uniqueKey]) {
            element.selected = true;
          }
        }
      }
    }
  }

  chooseFocusedItem(): any {
    if (this.filteredData && this.filteredData.length) {
      return this.filteredData[0];
    }
  }

  filterItem(query: string, data: Array<any>): void {
    query = query.toLowerCase();
    this.filteredData = data.filter((item) => {
      let searchKey = '';
      for (const key of this.display) {
        searchKey = searchKey.concat(item[key], ' ');
      }
      searchKey = searchKey.toLowerCase();
      return ~searchKey.indexOf(query);
    });
  }

  isFocused(item: any): boolean {
    return this.focusedItem && this.focusedItem.id === item.id;
  }

  openDropdown(): void {
    this.init();
    this.selectDropdown.openDropdown();
  }

  focusDropdown(): void {
    this.selectDropdown.focusOnDropdown();
  }

  scrollToItem(item: any): void {
    const itemIndex = _.findIndex(this.filteredData, { id: item.id });
    const itemElement = this.itemsElements.toArray()[itemIndex];
    this.scrollView.nativeElement.scrollTop = itemElement.nativeElement.offsetTop - this.scrollView.nativeElement.offsetTop;
  }

  submitSelected(data: Array<any>): void {
    data = data.filter((user) => {
      return user.selected;
    });
    data.map((item) => {
      delete item.selected;
    });
    this.output.emit(data);
  }

  getAnotherItem(direction: string, currentItem: any, items: Array<any> = this.filteredData): any {
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id === currentItem.id) {
          return chooseAnotherOption(i, items);
        }
      }
    }

    function chooseAnotherOption(index: number, _items: Array<any>): any {
      let focusedItem;
      const moveUp = direction === 'previous';
      const anotherIndex = moveUp ? index - 1 : index + 1;
      if (moveUp && anotherIndex >= 0 || !moveUp && anotherIndex < _items.length) {
        focusedItem = _items[anotherIndex];
      } else {
        const loopIndex = moveUp ? _items.length - 1 : 0;
        focusedItem = items[loopIndex];
      }
      return focusedItem;
    }
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    switch (event.key) {
      case 'Tab':
        if (this.selectDropdown.openedWithDelay) {
          this.selectDropdown.closeDropdown();
        }
        break;
      case 'Enter':
        if (this.selectDropdown.openedWithDelay) {
          event.preventDefault();
          this.submitSelected(this.data);
          this.selectDropdown.closeDropdown();
          return false;
        }
        break;
      case ' ':
        if (this.selectDropdown.openedWithDelay) {
          event.preventDefault();
          if (this.focusedItem) {
            this.focusedItem.selected = !this.focusedItem.selected;
          }
          return false;
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.selectDropdown.openedWithDelay) {
          this.focusedItem = this.focusedItem ? this.getAnotherItem('previous', this.focusedItem) : this.chooseFocusedItem();
          this.scrollToItem(this.focusedItem);
        } else {
          this.openDropdown();
        }
        return false;
      case 'ArrowDown':
        event.preventDefault();
        if (this.selectDropdown.openedWithDelay) {
          this.focusedItem = this.focusedItem ? this.getAnotherItem('next', this.focusedItem) : this.chooseFocusedItem();
          this.scrollToItem(this.focusedItem);
        } else {
          this.openDropdown();
        }
        return false;
    }
  }
}
