import { RiskType } from 'shared/enums/risk-type.enum';

export namespace RiskLevelConverter {
  export function convertRiskLevelToPost(risk: string) {
    switch (risk) {
      case RiskType.low:
        return 20;
      case RiskType.medium:
        return 70;
      case RiskType.increased:
        return 200;
      case RiskType.high:
        return 400;
    }
  }
}
