import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiUrl } from 'shared/enums/api-url.enum';
import { ApiInjuryType } from 'shared/interfaces/injury-type.interface';
import { InjuryType } from 'shared/models/injury-type.model';

@Injectable({
  providedIn: 'root'
})
export class InjuryTypeService {
  constructor(private http: HttpClient) { }

  getInjuryTypes(): Promise<Array<InjuryType>> {
    return this.http.get(`${ApiUrl.oamApi}/injurytype/list`).toPromise()
      .then((injuryTypes: Array<ApiInjuryType>) => {
        const convertedInjuryTypes = injuryTypes.map(injuryType => this.convertInjuryTypeToGet(injuryType));

        return convertedInjuryTypes.sort((injuryTypeOne, injuryTypeTwo) => {
          return injuryTypeOne.name > injuryTypeTwo.name ? 1 : -1;
        });
      });
  }

  convertInjuryTypeToGet(injuryType: ApiInjuryType): InjuryType {
    return {
      id: injuryType.ID,
      name: injuryType.Name
    };
  }
}
