import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import moment from 'moment';
// Component
import { DateTimePickerComponent } from 'shared/components/date-time-picker/date-time-picker.component';
// Directives
import { SimpleDropdownDirective } from 'shared/directives/simple-dropdown.directive';
// Models
import { Employee } from 'shared/models/employee.model';
// Services
import { AccidentStepperService } from 'shared/services/accident-stepper.service';
import { EmployeeService } from 'shared/services/employee.service';

@Component({
  selector: 'gw-accident-step1',
  templateUrl: './accident-step1.component.pug',
  styleUrls: ['./accident-step1.component.scss']
})
export class AccidentStep1Component implements OnInit {
  @ViewChild('datePicker') datePicker: SimpleDropdownDirective;
  @ViewChild('accidentTimePicker') accidentTimePicker: DateTimePickerComponent;
  accidentForm: FormGroup;
  submitted = false;
  employees: Array<Employee>;

  constructor(
    private accidentStepperService: AccidentStepperService,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  async ngOnInit() {
    this.employees = this.route.snapshot.data['employees'];
    this.initForm();
  }

  initForm(): void {
    const stepData = this.accidentStepperService.formSteps.step1;

    this.accidentForm = this.formBuilder.group({
      date: [(stepData && stepData.date) || '', Validators.required],
      location: [(stepData && stepData.location || ''), Validators.required],
      injuredPerson: [(stepData && stepData.injuredPerson) || '', Validators.required],
      reportedBy: [(stepData && stepData.reportedBy) || this.employeeService.getDefaultEmployee() || '', Validators.required],
      witnesses: [(stepData && stepData.witnesses) || []],
    });
  }

  setControlValue(data: any, key: string): void {
    this.accidentForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  applyDate(momentDate: moment.Moment): void {
    const date = moment(momentDate).format('YYYY-MM-DD HH:mm'); // ISO format
    this.accidentForm.controls['date'].setValue(date);
    if (this.datePicker) {
      this.datePicker.closeDropdown();
    }
  }

  submitForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateStep(form);
      this.goToNextStep();
    }
  }

  updateStep(form: FormGroup): void {
    this.accidentStepperService.formSteps.step1 = this.accidentStepperService.convertStep1FormToObject(form);
    this.accidentStepperService.updateInStorage();
  }

  goToNextStep(): void {
    this.router.navigate(['/ehs/accident/description'], { replaceUrl: true });
  }

  keyDownDatePicker(event: any): void {
    if (this.accidentTimePicker) {
      this.accidentTimePicker.keydown(event);
    }
  }
}
