import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiUrl } from 'shared/enums/api-url.enum';
import { ApiBodyPart } from 'shared/interfaces/body-part.interface';
import { BodyPart } from 'shared/models/body-part.model';

@Injectable({
  providedIn: 'root'
})
export class BodyPartService {
  constructor(private http: HttpClient) { }

  getBodyParts(): Promise<Array<BodyPart>> {
    return this.http.get(`${ApiUrl.oamApi}/bodypart/list`).toPromise()
      .then((bodyParts: Array<ApiBodyPart>) => {
        const convertedBodyParts = bodyParts.map(bodyPart => this.convertBodyPartToGet(bodyPart));

        return convertedBodyParts.sort((bodyPartOne, bodyPartTwo) => {
          return bodyPartOne.name > bodyPartTwo.name ? 1 : -1;
        });
      });
  }

  convertBodyPartToGet(bodyPart: ApiBodyPart): BodyPart {
    return {
      id: bodyPart.ID,
      name: bodyPart.Name
    };
  }
}
