import { Injectable } from '@angular/core';
// Services
import { FileService, FileRawObject } from 'shared/services/file.service';
import { Attachment } from 'shared/models/attachment.model';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private fileService: FileService) { }

  convertAllToRawObjects(attachments: Array<Attachment>): Promise<Array<FileRawObject>> {
    if (attachments) {
      return Promise.all(attachments.map(async (attachment: Attachment) => {
        return await this.convertToRawObject(attachment);
      }));
    }
    return Promise.resolve([]);
  }

  async convertToRawObject(attachment: Attachment): Promise<FileRawObject> {
    const rawObject = await this.fileService.readFile(attachment.file);
    rawObject.name = attachment.name;
    return rawObject;
  }

  convertAllToAttachments(rawObjects: Array<FileRawObject>): Array<Attachment> {
    if (rawObjects) {
      return rawObjects.map((rawObject: FileRawObject) => this.convertToAttachment(rawObject));
    }
    return [];
  }

  convertToAttachment(rawObject: FileRawObject): Attachment {
    const file = this.fileService.base64ToBlob(rawObject.base64, rawObject.contentType);
    return new Attachment({
      name: rawObject.name,
      rotation: rawObject.rotation,
      checksum: rawObject.hash,
      file
    });
  }
}
