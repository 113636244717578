export class Attachment {
  url: string;
  file: File | Blob | any;
  name: string;
  size: number;
  extension: string;
  isImage: boolean;
  isDuplicated: boolean;
  toRemove: boolean;
  checksum: string;
  rotation: number;
  offline: boolean;
  preview: string;

  constructor(attachment: Partial<Attachment>) {
    this.url = attachment.url;
    this.file = attachment.file;
    this.name = attachment.file && attachment.file.name ? attachment.file.name : attachment.name;
    this.size = attachment.size;
    this.extension = this.getExtension() || '';
    this.isImage = this.isFileImage() || attachment.isImage;
    this.isDuplicated = attachment.isDuplicated || false;
    this.toRemove = attachment.toRemove || false;
    this.checksum = attachment.checksum;
    this.rotation = attachment.rotation;
    this.offline = attachment.offline;
    this.preview = attachment.preview;
  }

  getExtension(): string {
    if (this.url) {
      const index = {
        start: this.url.lastIndexOf('.') + 1,
        end: ~this.url.lastIndexOf('?') ? this.url.lastIndexOf('?') : this.url.length,
      };
      return this.url.substring(index.start, index.end);
    } else if (this.file && this.file.name || this.name) {
      const name = this.file && this.file.name ? this.file.name : this.name;
      const startIndex = name.lastIndexOf('.');
      if (~startIndex) {
        return name.substring(startIndex + 1);
      }
    }
  }

  isFileImage(): boolean {
    return !!~['jpg', 'jpeg', 'png', 'gif', 'svg'].indexOf(this.extension.toLowerCase());
  }
}
