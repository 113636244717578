import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { InjuryType } from 'shared/models/injury-type.model';
import { InjuryTypeService } from 'shared/services/injury-type.service';

@Injectable()
export class InjuryTypesResolve implements Resolve<any> {
  constructor(private injuryTypeService: InjuryTypeService) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Array<InjuryType>> {
    return this.injuryTypeService.getInjuryTypes();
  }
}
