import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
// Interfaces
import { TileURL } from 'shared/interfaces/tile.interface';
// Services
import { AccidentStepperService } from 'shared/services/accident-stepper.service';
import { EnvironmentalIncidentStepperService } from 'shared/services/environmental-incident-stepper.service';
import { NearMissStepperService } from 'shared/services/near-miss-stepper.service';
import { FormNames } from 'shared/services/stepper.service';
import { UnsafeConditionStepperService } from 'shared/services/unsafe-condition-stepper.service';

@Component({
  selector: 'gw-menu',
  templateUrl: './menu.component.pug',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  tiles: Array<TileURL>;
  translation = {
    menu: {
      accident: '',
      nearMiss: '',
      unsafeCondition: '',
      environmentalAccident: '',
    },
  };

  constructor(
    private accidentStepperService: AccidentStepperService,
    private incidentStepperService: EnvironmentalIncidentStepperService,
    private nearMissStepperService: NearMissStepperService,
    private unsafeConditionStepperService: UnsafeConditionStepperService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.fetchTranslation().then(() => this.createTiles());
  }

  private createTiles(): void {
    this.tiles = [
      {
        id: FormNames.accidentForm,
        imageUrl: '/assets/icons/icon-accident.svg',
        translationString: this.translation.menu.accident,
        url: '/ehs/accident'
      },
      {
        id: FormNames.nearMissForm,
        imageUrl: '/assets/icons/icon-file-warning.svg',
        translationString: this.translation.menu.nearMiss,
        url: '/ehs/near-miss'
      },
      {
        id: FormNames.unsaveConditionForm,
        imageUrl: '/assets/icons/icon-exclamation-circle.svg',
        translationString: this.translation.menu.unsafeCondition,
        url: '/ehs/unsafe-condition'
      },
      {
        id: FormNames.environmentalIncidentForm,
        imageUrl: '/assets/icons/icon-oil.svg',
        translationString: this.translation.menu.environmentalAccident,
        url: '/ehs/environmental-incident'
      },
    ];
  }

  handleTileClick(tile: TileURL): void {
    let stepperService: AccidentStepperService | EnvironmentalIncidentStepperService | NearMissStepperService | UnsafeConditionStepperService;
    switch (tile.id) {
      case FormNames.accidentForm:
        stepperService = this.accidentStepperService;
        break;
      case FormNames.nearMissForm:
        stepperService = this.nearMissStepperService;
        break;
      case FormNames.unsaveConditionForm:
        stepperService = this.unsafeConditionStepperService;
        break;
      case FormNames.environmentalIncidentForm:
        stepperService = this.incidentStepperService;
        break;
    }
    stepperService.removeFromStorage();
    this.router.navigate([tile.url]);
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'EHS.MENU.ACCIDENT',
      'EHS.MENU.NEAR_MISS',
      'EHS.MENU.UNSAFE_CONDITION',
      'EHS.MENU.ENVIRONMENTAL_INCIDENT',
    ]).toPromise();
    this.translation.menu.accident = translation['EHS.MENU.ACCIDENT'];
    this.translation.menu.nearMiss = translation['EHS.MENU.NEAR_MISS'];
    this.translation.menu.unsafeCondition = translation['EHS.MENU.UNSAFE_CONDITION'];
    this.translation.menu.environmentalAccident = translation['EHS.MENU.ENVIRONMENTAL_INCIDENT'];
  }
}
