import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date | moment.Moment, ...args: any[]): string {
    if (value) {
      return moment(value).format(args[0]);
    }
  }

}
