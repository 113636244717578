import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
// Services
import { BodyFreezerService } from 'shared/services/body-freezer.service';
import { HelperService } from 'shared/services/helper.service';
import { ModalService } from 'shared/services/modal.service';
// Components
import { ModalComponent } from 'shared/components/modal/modal.component';
// Directives
import { SimpleDropdownDirective } from 'shared/directives/simple-dropdown.directive';
// Models
import { Attachment } from 'shared/models/attachment.model';
// Interfaces
import { LightboxOption, LightboxService } from 'shared/services/lightbox.service';

@Component({
  selector: 'gw-lightbox',
  exportAs: 'gwLightbox',
  templateUrl: './modal-lightbox.component.pug',
  styleUrls: ['./modal-lightbox.component.scss']
})
export class ModalLightboxComponent extends ModalComponent implements OnInit {
  @ViewChild('dropdownMenu') dropdownMenu: SimpleDropdownDirective;
  currentImage: Attachment;
  gallery: Array<Attachment>;
  index: number;
  rotation: number;
  options: Array<LightboxOption>;

  constructor(
    public modalService: ModalService,
    protected bodyFreezer: BodyFreezerService,
    private helperService: HelperService,
    private lightboxService: LightboxService
  ) {
    super(modalService, bodyFreezer);
  }

  @HostListener('window:keydown', ['$event']) keyboardClick(event: KeyboardEvent): void {
    if (this.opened) {
      switch (event.key) {
        case 'Escape':
          this.close();
          break;
        case 'ArrowRight':
          this.goToNext();
          break;
        case 'ArrowLeft':
          this.goToPrevious();
          break;
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  rotate() {
    if (this.rotation && this.rotation !== 0 && !this.helperService.isIOS()) {
      return {
        'transform': `rotate(${this.rotation}deg)`,
      };
    }
  }

  hasNext(): boolean {
    if (this.gallery) {
      this.index = _.findIndex(this.gallery, { checksum: this.currentImage.checksum });
      return this.gallery.length - 1 > this.index;
    }
    return false;
  }

  hasPrevious(): boolean {
    if (this.gallery) {
      this.index = _.findIndex(this.gallery, { checksum: this.currentImage.checksum });
      return this.index > 0 ;
    }
    return false;
  }

  goToNext(): void {
    if (this.index < this.gallery.length - 1) {
      this.currentImage = this.gallery[this.index + 1];
    }
  }

  goToPrevious(): void {
    if (this.index > 0) {
      this.currentImage = this.gallery[this.index - 1];
    }
  }


  blindClick(): void {
    this.close();
  }

  elementClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.dropdownMenu && this.dropdownMenu.opened) {
      this.dropdownMenu.closeDropdown();
    }
  }

  emitOption(option: LightboxOption): void {
    this.lightboxService.emitEvent({ option, attachment: this.currentImage });
  }
}
