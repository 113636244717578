import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
// Models
import { Attachment } from 'shared/models/attachment.model';
// Services
import { UnsafeConditionStepperService } from 'shared/services/unsafe-condition-stepper.service';

@Component({
  selector: 'gw-unsafe-condition-step2',
  templateUrl: './unsafe-condition-step2.component.pug',
  styleUrls: ['./unsafe-condition-step2.component.scss']
})
export class UnsafeConditionStep2Component implements OnInit {
  descriptionForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private unsafeConditionStepperService: UnsafeConditionStepperService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initForm();
    this.changeDetector.detectChanges();
  }

  private initForm(): void {
    const stepData = this.unsafeConditionStepperService.formSteps.step2;
    this.descriptionForm = this.formBuilder.group({
      description: [(stepData && stepData.description) || '', Validators.required],
      solution: [(stepData && stepData.solution) || '', Validators.required],
      attachments: [(stepData && stepData.attachments) || [], ]
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  updateAtachments(attachments: Array<Attachment>): void {
    this.descriptionForm.controls.attachments.setValue(attachments);
  }

  submitForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateStep(form);
      this.goToNextStep();
    }
  }

  async updateStep(form: FormGroup): Promise<void> {
    this.unsafeConditionStepperService.formSteps.step2 = this.unsafeConditionStepperService.convertStep2FormToObject(form);
    await this.unsafeConditionStepperService.updateInStorage();
  }

  goToNextStep(): void {
    this.router.navigate(['/ehs/unsafe-condition/risk'], { replaceUrl: true });
  }
}
