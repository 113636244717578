import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
// Components
import { AttachmentComponent } from 'shared/components/attachment/attachment.component';
import { AttachmentsComponent } from 'shared/components/attachments/attachments.component';
import { CheckBoxComponent } from 'shared/components/check-box/check-box.component';
import { DatePickerComponent } from 'shared/components/date-picker/date-picker.component';
import { DateTimePickerComponent } from 'shared/components/date-time-picker/date-time-picker.component';
import { HeaderComponent } from 'shared/components/header/header.component';
import { ModalLightboxComponent } from 'shared/components/modal-lightbox/modal-lightbox.component';
import { ModalComponent } from 'shared/components/modal/modal.component';
import { MultiselectComponent } from 'shared/components/multiselect/multiselect.component';
import { SelectComponent } from 'shared/components/select/select.component';
import { TilesComponent } from 'shared/components/tiles/tiles.component';
import { TitleBarComponent } from 'shared/components/title-bar/title-bar.component';
import { AutosizeDirective } from 'shared/directives/autosize.directive';
// Directives
import { SimpleDropdownDirective } from 'shared/directives/simple-dropdown.directive';
// Pipes
import { DateFormatPipe } from 'shared/pipes/date-format.pipe';
// Resolvers
import { BodyPartsResolve } from 'shared/resolvers/body-parts.resolve';
import { EmployeesResolve } from 'shared/resolvers/employees.resolve';
import { InjuryTypesResolve } from 'shared/resolvers/injury-types.resolve';
import { AccidentStepperService } from 'shared/services/accident-stepper.service';
// Services
import { AccidentService } from 'shared/services/accident.service';
import { AlertService } from 'shared/services/alert.service';
import { AttachmentService } from 'shared/services/attachment.service';
import { AuthenticationService } from 'shared/services/authentication.service';
import { BodyFreezerService } from 'shared/services/body-freezer.service';
import { EmployeeService } from 'shared/services/employee.service';
import { EnvironmentalIncidentStepperService } from 'shared/services/environmental-incident-stepper.service';
import { FileService } from 'shared/services/file.service';
import { HelperService } from 'shared/services/helper.service';
import { I18nService } from 'shared/services/i18n.service';
import { LightboxService } from 'shared/services/lightbox.service';
import { ModalService } from 'shared/services/modal.service';
import { NearMissStepperService } from 'shared/services/near-miss-stepper.service';
import { NearMissService } from 'shared/services/near-miss.service';
import { StepperService } from 'shared/services/stepper.service';
import { StorageService } from 'shared/services/storage.service';
import { UnsafeConditionStepperService } from 'shared/services/unsafe-condition-stepper.service';
import { UnsafeConditionService } from 'shared/services/unsafe-condition.service';
import { UserProfileService } from 'shared/services/user-profile.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [
    AttachmentComponent,
    AttachmentsComponent,
    CheckBoxComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    HeaderComponent,
    ModalComponent,
    ModalLightboxComponent,
    MultiselectComponent,
    SelectComponent,
    TilesComponent,
    TitleBarComponent,
    AutosizeDirective,
    SimpleDropdownDirective,
    DateFormatPipe,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AttachmentComponent,
    AttachmentsComponent,
    CheckBoxComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    HeaderComponent,
    ModalComponent,
    ModalLightboxComponent,
    MultiselectComponent,
    SelectComponent,
    TilesComponent,
    TitleBarComponent,
    AutosizeDirective,
    SimpleDropdownDirective,
    DateFormatPipe,
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AccidentService,
        AccidentStepperService,
        AlertService,
        AuthenticationService,
        AttachmentService,
        BodyFreezerService,
        EmployeeService,
        FileService,
        HelperService,
        I18nService,
        EnvironmentalIncidentStepperService,
        LightboxService,
        ModalService,
        NearMissService,
        NearMissStepperService,
        StepperService,
        StorageService,
        UnsafeConditionService,
        UnsafeConditionStepperService,
        UserProfileService,
        BodyPartsResolve,
        EmployeesResolve,
        InjuryTypesResolve,
      ]
    };
  }
}
