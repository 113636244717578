import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Employee } from 'shared/models/employee.model';
import { EmployeeService } from 'shared/services/employee.service';

@Injectable()
export class EmployeesResolve implements Resolve<any> {
  constructor(private employeeService: EmployeeService) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Array<Employee>> {
    return this.employeeService.getEmployees();
  }
}
