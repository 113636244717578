import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// Interfaces
import { Tile } from 'shared/interfaces/tile.interface';

export enum TileHeight {
  tall = 'Tall',
  medium = 'Medium',
  small = 'Small'
}

@Component({
  selector: 'gw-tiles',
  templateUrl: './tiles.component.pug',
  styleUrls: ['./tiles.component.scss']
})
export class TilesComponent implements OnInit {
  @Input() tiles: Array<Tile>;
  @Input() selectedIds: Array<string>;
  @Input() showBackground = false;
  @Input() tileHeight: TileHeight = TileHeight.tall;
  @Input() error = false;
  @Output() tileClick = new EventEmitter();
  tileHeights = TileHeight;

  constructor() { }

  ngOnInit() { }
}
