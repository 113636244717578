import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storesChanged = new Array<string>();
  storesChangedSubject = new Subject<Array<string>>();
  storesChangedObservable = this.storesChangedSubject.asObservable();

  constructor() { }

  setStorage(storeName: string, data: any): void {
    sessionStorage.setItem(storeName, JSON.stringify(data));
    this.storesChanged.push(storeName);
    this.storesChangedSubject.next(this.storesChanged);
  }

  getStorage(storeName: string): any {
    return JSON.parse(sessionStorage.getItem(storeName));
  }

  removeFromStorage(storeName: string) {
    sessionStorage.removeItem(storeName);
    const index = this.storesChanged.indexOf(storeName);
    this.storesChanged.splice(index, 1);
    this.storesChangedSubject.next(this.storesChanged);
  }

  setLocalStorage(storeName: string, data: any): void {
    localStorage.setItem(storeName, JSON.stringify(data));
    this.storesChanged.push(storeName);
    this.storesChangedSubject.next(this.storesChanged);
  }

  getLocalStorage(storeName: string): any {
    return JSON.parse(localStorage.getItem(storeName));
  }

  removeFromLocalStorage(storeName: string): void {
    localStorage.removeItem(storeName);
    const index = this.storesChanged.indexOf(storeName);
    this.storesChanged.splice(index, 1);
    this.storesChangedSubject.next(this.storesChanged);
  }
}
