import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
// Services
import { ModalService } from './modal.service';
// Components
import { ModalLightboxComponent } from 'shared/components/modal-lightbox/modal-lightbox.component';
// Models
import { Attachment } from './../models/attachment.model';

export interface LightboxOption {
  id: 'remove' | 'edit';
  name: string;
}

export interface LightboxEvent {
  option: LightboxOption;
  attachment: Attachment;
}

@Injectable({
  providedIn: 'root'
})
export class LightboxService {
  private lightboxModal: ModalLightboxComponent;
  eventSubject = new Subject<LightboxEvent>();
  eventObservable = this.eventSubject.asObservable();

  constructor(private modalService: ModalService) { }

  show(image: Attachment, gallery?: Array<Attachment>, options?: Array<LightboxOption>): void {
    this.modalService.open('lightboxModal');
    this.lightboxModal = _.find(this.modalService.modals, {id: 'lightboxModal'}) as ModalLightboxComponent;
    this.lightboxModal.currentImage = image;
    this.lightboxModal.gallery = gallery;
    this.lightboxModal.rotation = image.rotation;
    this.lightboxModal.options = options;
  }

  close(): void {
    this.modalService.close('lightboxModal');
  }

  emitEvent(event: LightboxEvent): void {
    this.eventSubject.next(event);
  }
}
