import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import moment from 'moment';
import { RiskLevelConverter } from 'shared/converters/risk-level.converter';
import { ApiUrl } from 'shared/enums/api-url.enum';
import { ApiUnsafeCondition } from 'shared/interfaces/unsafe-condition.interface';
import { Attachment } from 'shared/models/attachment.model';
import { UnsafeCondition } from 'shared/models/unsafe-condition.model';

@Injectable({
  providedIn: 'root'
})
export class UnsafeConditionService {
  unsafeConditionID: number;

  constructor(
    private http: HttpClient
  ) { }

  addReport(unsafeCondition: UnsafeCondition): Promise<void> {
    return this.http.post(
      `${ApiUrl.icmApi}/continuousimprovementprocess`,
      this.convertReportedUnsafeConditionToPost(unsafeCondition)
    ).toPromise()
      .then((unsafeConditionID: number) => {
        this.unsafeConditionID = unsafeConditionID;
      })
      .catch(async error => {
        throw error;
      });
  }

  addAttachment(payload: { id: number, attachment: Attachment }): Promise<Object> {
    const formData: FormData = new FormData();
    let params: HttpParams = new HttpParams();
    formData.append('attachment', payload.attachment.file);
    params = params.append('continuousImprovmentProcessID', payload.id.toString());

    return this.http.post(`${ApiUrl.icmApi}/continuousimprovementprocess/addAttachment`, formData, { params }).toPromise()
      .catch((error) => {
        throw error;
      });
  }

  addAllAttachments(attachments: Array<Attachment>): Promise<Array<Attachment>> {
    const attachmentsPromises = [];

    attachments.forEach(attachment => {
      attachmentsPromises.push(this.addAttachment({ id: this.unsafeConditionID, attachment }));
    });

    return Promise.all(attachmentsPromises)
      .catch((error) => {
        throw error;
      });
  }

  async reportUnsafeCondition(unsafeCondition: UnsafeCondition): Promise<void> {
    await this.addReport(unsafeCondition);

    if (unsafeCondition.attachments) {
      await this.addAllAttachments(unsafeCondition.attachments);
    }
  }

  convertReportedUnsafeConditionToPost(unsafeCondition: UnsafeCondition): ApiUnsafeCondition {
    return {
      Type: 'UnsafeCondition',
      Date: moment(unsafeCondition.date).format(),
      Location: unsafeCondition.location,
      ReportEmployeeID: unsafeCondition.reportedBy.id,
      Description: unsafeCondition.description,
      ProposedSolution: unsafeCondition.solution,
      PotentialRiskLevel: RiskLevelConverter.convertRiskLevelToPost(unsafeCondition.risk)
    };
  }
}
