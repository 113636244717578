import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
// Services
import { ModalService } from 'shared/services/modal.service';
import { BodyFreezerService } from 'shared/services/body-freezer.service';

@Component({
  selector: 'gw-modal',
  exportAs: 'gwModal',
  templateUrl: './modal.component.pug',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalContent') modalContent: ElementRef;
  @Input() id: string;
  @Input() fullHeight = false;
  @Input() offCanvas = false;
  @Input() small = false;
  @Output() onModalClose = new EventEmitter<any>();
  data: any;
  opened = false;
  hidden = false;

  @Input() beforeClose = (): Promise<void> => Promise.resolve();

  constructor(public modalService: ModalService, protected bodyFreezer: BodyFreezerService) { }

  ngOnInit() {
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.bodyFreezer.unfreezeBody();
  }

  open(data?: any): void {
    this.data = data;
    this.opened = true;
    this.bodyFreezer.freezeBody();
  }

  close($event?): void {
    const closeModal = () => {
      this.opened = false;
      this.bodyFreezer.unfreezeBody();
      this.onModalClose.emit($event);
    };

    if (this.beforeClose) {
      this.beforeClose()
        .then(() => {
          closeModal();
        })
        .catch(error => {});
    } else {
      closeModal();
    }
  }

  show(data?: any): void {
    if (data) {
      this.data = data;
    }
    this.hidden = false;
    this.bodyFreezer.freezeBody();
  }

  hide(): void {
    this.hidden = true;
    this.bodyFreezer.unfreezeBody();
  }
}
