import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import moment from 'moment';
// Models
import { Attachment } from 'shared/models/attachment.model';
import { Employee } from 'shared/models/employee.model';
import { UnsafeCondition } from 'shared/models/unsafe-condition.model';
// Services
import { AttachmentService } from 'shared/services/attachment.service';
import { FileRawObject } from 'shared/services/file.service';
import { FormNames, StepperService } from 'shared/services/stepper.service';
import { StorageService } from 'shared/services/storage.service';

export class UnsafeConditionSteps {
  constructor(
    public step1?: UnsafeConditionStep1,
    public step2?: UnsafeConditionStep2,
    public step3?: UnsafeConditionStep3,
  ) { }
}

export class UnsafeConditionStepsStorage {
  constructor(
    public step1?: UnsafeConditionStep1,
    public step2?: UnsafeConditionStep2Storage,
    public step3?: UnsafeConditionStep3,
  ) { }
}

export interface UnsafeConditionStep1 {
  date: moment.Moment;
  location: string;
  reportedBy: Employee;
}

export interface UnsafeConditionStep2 {
  description: string;
  solution: string;
  attachments: Array<Attachment>;
}

export interface UnsafeConditionStep2Storage {
  description: string;
  solution: string;
  attachments: Array<FileRawObject>;
}

export interface UnsafeConditionStep3 {
  risk: string;
}

@Injectable({
  providedIn: 'root'
})
export class UnsafeConditionStepperService extends StepperService {
  formSteps: UnsafeConditionSteps;
  formName = FormNames.unsaveConditionForm;

  constructor(
    protected storageService: StorageService,
    private attachmentService: AttachmentService
  ) {
    super(storageService);
    this.formSteps = this.getFromStorage() || new UnsafeConditionSteps();
  }

  getFromStorage(): UnsafeConditionSteps {
    const storedUnsafeConditionSteps: UnsafeConditionStepsStorage = super.getFromStorage();
    const unsafeConditionSteps = new UnsafeConditionSteps();
    if (storedUnsafeConditionSteps) {
      unsafeConditionSteps.step1 = { ...storedUnsafeConditionSteps.step1 };
      unsafeConditionSteps.step2 = {
        description: storedUnsafeConditionSteps.step2 && storedUnsafeConditionSteps.step2.description,
        solution: storedUnsafeConditionSteps.step2 && storedUnsafeConditionSteps.step2.solution,
        attachments: storedUnsafeConditionSteps.step2
          && this.attachmentService.convertAllToAttachments(storedUnsafeConditionSteps.step2.attachments)
      };
      unsafeConditionSteps.step3 = { ...storedUnsafeConditionSteps.step3 };
    }
    return unsafeConditionSteps;
  }

  getFormData(): UnsafeCondition {
    return {
      ...this.formSteps.step1,
      ...this.formSteps.step2,
      ...this.formSteps.step3
    };
  }

  removeFromStorage(): void {
    this.formSteps = new UnsafeConditionSteps();
    return super.removeFromStorage();
  }

  async updateInStorage(): Promise<void> {
    const unsafeConditionStorage = new UnsafeConditionStepsStorage();
    unsafeConditionStorage.step1 = { ...this.formSteps.step1 };
    unsafeConditionStorage.step2 = {
      description: this.formSteps.step2 && this.formSteps.step2.description,
      solution: this.formSteps.step2 && this.formSteps.step2.solution,
      attachments: this.formSteps.step2 && await this.attachmentService.convertAllToRawObjects(this.formSteps.step2.attachments)
    };
    unsafeConditionStorage.step3 = { ...this.formSteps.step3 };

    super.updateInStorage(unsafeConditionStorage);
  }

  convertStep1FormToObject(form: FormGroup): UnsafeConditionStep1 {
    return {
      date: form.value.date,
      location: form.value.location,
      reportedBy: form.value.reportedBy,
    };
  }

  convertStep2FormToObject(form: FormGroup): UnsafeConditionStep2 {
    return {
      description: form.value.description,
      solution: form.value.solution,
      attachments: form.value.attachments
    };
  }

  convertStep3FormToObject(form: FormGroup): UnsafeConditionStep3 {
    return {
      risk: form.value.risk,
    };
  }
}
