import { Component, OnInit, OnChanges, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { HostBinding, SimpleChanges, AfterContentChecked, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs';
// Services
import { FileService } from './../../services/file.service';
import { LightboxService, LightboxOption } from './../../services/lightbox.service';
// Models
import { Attachment } from 'shared/models/attachment.model';

class RotationStyles {
  'transform-origin': string;
  'transform': string;
  'height': string;
  'width': string;
  'left': string;
  'top': string;
}

@Component({
  selector: 'gw-attachment',
  templateUrl: './attachment.component.pug',
  styleUrls: ['./attachment.component.scss'],
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() attachment: Attachment;
  @Input() viewOnly = false;
  @Input() gallery: Array<Attachment>;
  @Input() coverText: string;
  @Input() imageSize = 'cover';
  @Input() lightboxOptions: Array<LightboxOption>;
  @Output() removed = new EventEmitter();
  @HostBinding('class.attachment-container--box') isBox: boolean;
  @HostBinding('class.attachment-container--image') isImage: boolean;
  imageTag: HTMLElement;
  imgSrc: string;
  rotationStyles = new RotationStyles();
  emptyImageBackground = false;
  offlineSubscription: Subscription;

  constructor(
    public element: ElementRef,
    private fileService: FileService,
    private numberPipe: DecimalPipe,
    private changeDetector: ChangeDetectorRef,
    private lightboxService: LightboxService,
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterContentChecked() {
    this.imageTag = this.element.nativeElement;
    const rotation = this.attachment.rotation;

    if (rotation && rotation !== 0) {
      this.rotationStyles['transform'] = `rotate(${rotation}deg)`;
      this.rotationStyles['left'] = rotation === 90 ? '100%' : '';
      this.rotationStyles['top'] = rotation === 270 ? '100%' : '';
      if (rotation === 90 || rotation === 270) {
        this.rotationStyles['height'] = `${this.imageTag.offsetWidth - 2}px`;
        this.rotationStyles['width'] = `${this.imageTag.offsetHeight - 2}px`;
        this.rotationStyles['transform-origin'] = '0 0';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['attachment']) {
      this.init();
    }
  }

  init(): void {
    if (this.attachment) {
      this.isBox = this.attachment.isImage;
      this.isImage = this.attachment.isImage;
      this.generateTemporaryImage();
    }
  }

  removeAttachment(): void {
    this.removed.emit();
  }

  fullName(file: File): string {
    return file.name + '.' + file.type;
  }

  sizeWithUnit(size: any): string {
    let sizeUnit: string;
    if (size >= 1000000) {
      size = this.numberPipe.transform(size / 1000000, '1.0-1');
      sizeUnit = 'MB';
    } else if (size >= 1000) {
      size = this.numberPipe.transform(size / 1000, '1.0-0');
      sizeUnit = 'kB';
    } else {
      sizeUnit = 'B';
    }
    return size + ' ' + sizeUnit;
  }

  generateTemporaryImage(): void {
    if (this.isImage) {
      if (this.attachment.url) {
        this.imgSrc = this.attachment.url;
      } else if (this.attachment.file) {
        this.fileService.generateImagePreview(this.attachment.file).then(image => {
          this.attachment.preview = image;
          this.imgSrc = image;
          this.changeDetector.markForCheck();
        });
      }
    }
  }

  showLightbox(): void {
    if (this.isImage) {
      this.lightboxService.show(
        this.attachment,
        this.gallery,
        this.lightboxOptions
      );
    }
  }

  imageLoaded(): void {
    this.emptyImageBackground = true;
  }
}

