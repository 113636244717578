import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import moment from 'moment';
import { ApiUrl } from 'shared/enums/api-url.enum';
import { MeasureTypes } from 'shared/enums/measure-types.enum';
import { ApiAccident } from 'shared/interfaces/accident.interface';
import { Accident } from 'shared/models/accident.model';
import { Attachment } from 'shared/models/attachment.model';

@Injectable({
  providedIn: 'root'
})
export class AccidentService {
  accidentID: number;

  constructor(
    private http: HttpClient
  ) { }

  addReport(accident: Accident): Promise<void> {
    return this.http.post(
      `${ApiUrl.oamApi}/accident`,
      this.convertReportedAccidentToPost(accident)
    ).toPromise()
      .then((accidentID: number) => {
        this.accidentID = accidentID;
      })
      .catch(async error => {
        throw error;
      });
  }

  addAttachment(payload: { id: number, attachment: Attachment }): Promise<Object> {
    const formData: FormData = new FormData();
    let params: HttpParams = new HttpParams();
    formData.append('attachment', payload.attachment.file);
    params = params.append('accidentID', payload.id.toString());

    return this.http.post(`${ApiUrl.oamApi}/accident/addAttachment`, formData, { params }).toPromise()
      .catch((error) => {
        throw error;
      });
  }

  addAllAttachments(attachments: Array<Attachment>): Promise<Array<Attachment>> {
    const attachmentsPromises = [];

    attachments.forEach(attachment => {
      attachmentsPromises.push(this.addAttachment({ id: this.accidentID, attachment }));
    });

    return Promise.all(attachmentsPromises)
      .catch((error) => {
        throw error;
      });
  }

  async reportAccident(accident: Accident): Promise<void> {
    await this.addReport(accident);

    if (accident.attachments) {
      await this.addAllAttachments(accident.attachments);
    }
  }

  convertReportedAccidentToPost(accident: Accident): ApiAccident {
    return {
      AccidentType: 'Accident',
      Date: moment(accident.date).format(),
      Location: accident.location,
      Description: accident.description,
      ReportEmployeeID: accident.reportedBy.id,
      InjuredEmployeeID: accident.injuredPerson.id,
      WitnessesEmployeeIDs: accident.witnesses.map(witness => witness.id),
      InjuryTypes: accident.injuryType.map(injuryType => injuryType.id),
      InjuredBodyParts: accident.bodyPart.map(bodyPart => bodyPart.id),
      FirstAid: accident.measures.includes(MeasureTypes.firstAid),
      DoctorTreatment: accident.measures.includes(MeasureTypes.consultation),
      RescueOperation: accident.measures.includes(MeasureTypes.rescueOperation),
      OtherMeasure: accident.remark
    };
  }
}
