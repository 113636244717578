import { Injectable } from '@angular/core';
// Services
import { HelperService } from 'shared/services/helper.service';

@Injectable()
export class BodyFreezerService {
  bodyElement: HTMLElement = document.body;
  rootScrollTop: number;
  count = 0;

  constructor(private helperService: HelperService) { }

  freezeBody(): void {
    this.count ++;
    this.bodyElement.classList.add('body--frozen');
    if (this.helperService.isIOS()) {
      this.rootScrollTop = this.getScrollingElement().scrollTop;
      this.bodyElement.classList.add('body--fixed');
      this.bodyElement.style.top = `-${this.rootScrollTop}px`;
    }
  }

  unfreezeBody(): void {
    this.count --;

    if (this.count < 1) {
      this.bodyElement.classList.remove('body--frozen');
      if (this.helperService.isIOS()) {
        this.bodyElement.classList.remove('body--fixed');
        this.bodyElement.style.top = `auto`;
        this.getScrollingElement().scrollTo(0, this.rootScrollTop);
        delete this.rootScrollTop;
      }
      this.count = 0;
    }
  }

  getScrollingElement(): Element {
    if (document.scrollingElement) {
      return document.scrollingElement;
    }

    const initial = document.documentElement.scrollTop;
    document.documentElement.scrollTop = initial + 1;
    const updated = document.documentElement.scrollTop;
    document.documentElement.scrollTop = initial;

    return updated > initial ? document.documentElement : document.body;
  }
}
