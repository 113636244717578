import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-accident',
  templateUrl: './accident.component.pug',
  styleUrls: ['./accident.component.scss']
})
export class AccidentComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
