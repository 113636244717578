import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
// Modules
import { EhsRoutingModule } from 'ehs/ehs-routing.module';
import { EhsComponent } from 'ehs/ehs.component';
import { AccidentStep1Component } from 'ehs/views/accident/accident-step1/accident-step1.component';
import { AccidentStep2Component } from 'ehs/views/accident/accident-step2/accident-step2.component';
// Components
import { AccidentComponent } from 'ehs/views/accident/accident.component';
import { EnvironmentalIncidentStep1Component } from 'ehs/views/environmental-incident/environmental-incident-step1/environmental-incident-step1.component';
import { EnvironmentalIncidentStep2Component } from 'ehs/views/environmental-incident/environmental-incident-step2/environmental-incident-step2.component';
import { EnvironmentalIncidentStep3Component } from 'ehs/views/environmental-incident/environmental-incident-step3/environmental-incident-step3.component';
import { EnvironmentalIncidentStep4Component } from 'ehs/views/environmental-incident/environmental-incident-step4/environmental-incident-step4.component';
import { EnvironmentalIncidentComponent } from 'ehs/views/environmental-incident/environmental-incident.component';
import { LoginPageComponent } from 'ehs/views/login-page/login-page.component';
import { MenuComponent } from 'ehs/views/menu/menu.component';
import { NearMissStep1Component } from 'ehs/views/near-miss/near-miss-step1/near-miss-step1.component';
import { NearMissStep2Component } from 'ehs/views/near-miss/near-miss-step2/near-miss-step2.component';
import { NearMissStep3Component } from 'ehs/views/near-miss/near-miss-step3/near-miss-step3.component';
import { NearMissStep4Component } from 'ehs/views/near-miss/near-miss-step4/near-miss-step4.component';
import { NearMissComponent } from 'ehs/views/near-miss/near-miss.component';
import { UnsafeConditionStep2Component } from 'ehs/views/unsafe-condition/unsafe-condition-step2/unsafe-condition-step2.component';
import { UnsafeConditionStep3Component } from 'ehs/views/unsafe-condition/unsafe-condition-step3/unsafe-condition-step3.component';
import { UnsafeConditionComponent } from 'ehs/views/unsafe-condition/unsafe-condition.component';
// Guards
import { LoginGuard } from 'shared/guards/login.guard';
import { LogoutGuard } from 'shared/guards/logout.guard';
import { SharedModule } from 'shared/shared.module';

import { AccidentStep3Component } from './views/accident/accident-step3/accident-step3.component';
import { UnsafeConditionStep1Component } from './views/unsafe-condition/unsafe-condition-step1/unsafe-condition-step1.component';

@NgModule({
  declarations: [
    AccidentComponent,
    AccidentStep1Component,
    AccidentStep2Component,
    AccidentStep3Component,
    EhsComponent,
    EnvironmentalIncidentComponent,
    EnvironmentalIncidentStep1Component,
    EnvironmentalIncidentStep2Component,
    EnvironmentalIncidentStep3Component,
    EnvironmentalIncidentStep4Component,
    LoginPageComponent,
    MenuComponent,
    NearMissComponent,
    NearMissStep1Component,
    NearMissStep2Component,
    NearMissStep3Component,
    NearMissStep4Component,
    UnsafeConditionComponent,
    UnsafeConditionStep1Component,
    UnsafeConditionStep2Component,
    UnsafeConditionStep3Component,
  ],
  imports: [
    CommonModule,
    EhsRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TranslateModule,
  ],
  providers: [
    LoginGuard,
    LogoutGuard
  ]
})
export class EhsModule {}
