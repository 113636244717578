import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// Models
import { Attachment } from 'shared/models/attachment.model';
import { BodyPart } from 'shared/models/body-part.model';
import { InjuryType } from 'shared/models/injury-type.model';
// Services
import { AccidentStepperService } from 'shared/services/accident-stepper.service';

@Component({
  selector: 'gw-accident-step2',
  templateUrl: './accident-step2.component.pug',
  styleUrls: ['./accident-step2.component.scss']
})
export class AccidentStep2Component implements OnInit {
  bodyParts: Array<BodyPart>;
  injuryTypes: Array<InjuryType>;
  descriptionForm: FormGroup;
  submitted: boolean;

  constructor(
    private accidentStepperService: AccidentStepperService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.bodyParts = this.route.snapshot.data['bodyParts'];
    this.injuryTypes = this.route.snapshot.data['injuryTypes'];
    this.initForm();
    this.changeDetector.detectChanges();
  }

  private initForm(): void {
    const stepData = this.accidentStepperService.formSteps.step2;

    this.descriptionForm = this.formBuilder.group({
      injuryType: [(stepData && stepData.injuryType) || [], Validators.required],
      bodyPart: [(stepData && stepData.bodyPart) || [], Validators.required],
      description: [(stepData && stepData.description) || '', Validators.required],
      attachments: [(stepData && stepData.attachments) || '']
    });
  }

  setControlValue(data: any, key: string): void {
    this.descriptionForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateStep(form);
      this.goToNextStep();
    }
  }

  updateStep(form: FormGroup): void {
    this.accidentStepperService.formSteps.step2 = this.accidentStepperService.convertStep2FormToObject(form);
    this.accidentStepperService.updateInStorage();
  }

  goToNextStep(): void {
    this.router.navigate(['/ehs/accident/measures'], { replaceUrl: true });
  }

  updateAtachments(attachments: Array<Attachment>): void {
    this.descriptionForm.controls.attachments.setValue(attachments);
  }
}
