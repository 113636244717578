import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { TileHeight } from 'shared/components/tiles/tiles.component';
// Enums
import { MaterialDamage } from 'shared/enums/material-damage.enum';
// Interfaces
import { Tile } from 'shared/interfaces/tile.interface';
// Services
import { NearMissStepperService } from 'shared/services/near-miss-stepper.service';

@Component({
  selector: 'gw-near-miss-step3',
  templateUrl: './near-miss-step3.component.pug',
  styleUrls: ['./near-miss-step3.component.scss']
})
export class NearMissStep3Component implements OnInit {
  damagesForm: FormGroup;
  tiles: Array<Tile>;
  tileHeights = TileHeight;
  submitted = false;
  translation = {
    damages: {
      yes: '',
      no: ''
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private nearMissStepperService: NearMissStepperService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    await this.fetchTranslation();
    this.createTiles();
    this.initForm();
  }

  private initForm() {
    const stepData = this.nearMissStepperService.formSteps.step3;
    this.damagesForm = this.formBuilder.group({
      damages: [stepData && stepData.damages || '', Validators.required]
    });
    if (stepData && stepData.damageDescription) {
      this.addDescriptionControl(stepData.damageDescription);
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  private createTiles(): void {
    this.tiles = [
      {
        id: MaterialDamage.yes,
        svgUrl: '/assets/icons/sprite-measures.svg#check-circle',
        translationString: this.translation.damages.yes,
        smallIcon: true,
      },
      {
        id: MaterialDamage.no,
        svgUrl: '/assets/icons/sprite-measures.svg#cross',
        translationString: this.translation.damages.no,
        smallIcon: true,
      },
    ];
  }

  toggleTile(tile: Tile): void {
    if (this.damagesForm.controls.damages.value !== tile.id) {
      this.damagesForm.controls.damages.setValue(tile.id);

      if (tile.id === MaterialDamage.yes) {
        this.addDescriptionControl();
      } else {
        this.removeDescriptionControl();
      }
    }
  }

  submitForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateStep(form);
      this.goToNextStep();
    }
  }

  updateStep(form: FormGroup): void {
    this.nearMissStepperService.formSteps.step3 = this.nearMissStepperService.convertStep3FormToObject(form);
    this.nearMissStepperService.updateInStorage();
  }

  goToNextStep(): void {
    this.router.navigate(['/ehs/near-miss/risk'], { replaceUrl: true });
  }

  private addDescriptionControl(description = '') {
    this.damagesForm.addControl('description', new FormControl(description, Validators.required));
    this.changeDetector.detectChanges();
  }

  private removeDescriptionControl() {
    this.damagesForm.removeControl('description');
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'GLOBAL.ANSWER.YES',
      'GLOBAL.ANSWER.NO',
    ]).toPromise();
    this.translation.damages.yes = translation['GLOBAL.ANSWER.YES'];
    this.translation.damages.no = translation['GLOBAL.ANSWER.NO'];
  }

}
