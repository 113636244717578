import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'gw-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  notificationOptions = {
    theClass: 'notification',
    position: ['bottom', 'right'],
    timeOut: 3000,
    maxStack: 1
  };

  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
    moment.locale('en');
  }
}
