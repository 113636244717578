import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import moment from 'moment';
import { Accident } from 'shared/models/accident.model';
import { Attachment } from 'shared/models/attachment.model';
import { BodyPart } from 'shared/models/body-part.model';
import { Employee } from 'shared/models/employee.model';
import { InjuryType } from 'shared/models/injury-type.model';
import { AttachmentService } from 'shared/services/attachment.service';
import { FileRawObject } from 'shared/services/file.service';
import { FormNames, StepperService } from 'shared/services/stepper.service';
import { StorageService } from 'shared/services/storage.service';

export class AccidentSteps {
  constructor(
    public step1?: AccidentStep1,
    public step2?: AccidentStep2,
    public step3?: AccidentStep3
  ) { }
}

export class AccidentStepsStorage {
  constructor(
    public step1?: AccidentStep1,
    public step2?: AccidentStep2Storage,
    public step3?: AccidentStep3
  ) { }
}

export interface AccidentStep1 {
  date: moment.Moment;
  location: string;
  injuredPerson: Employee;
  reportedBy: Employee;
  witnesses?: Employee[];
}

export interface AccidentStep2 {
  injuryType: Array<InjuryType>;
  bodyPart: Array<BodyPart>;
  description: string;
  attachments: Array<Attachment>;
}

export interface AccidentStep2Storage {
  injuryType: Array<InjuryType>;
  bodyPart: Array<BodyPart>;
  description: string;
  attachments: Array<FileRawObject>;
}

export interface AccidentStep3 {
  measures: Array<string>;
  remark?: string;
}

@Injectable({
  providedIn: 'root'
})

export class AccidentStepperService extends StepperService {
  formData: Accident;
  formSteps: AccidentSteps;
  formName = FormNames.accidentForm;

  constructor(
    private attachmentService: AttachmentService,
    protected storageService: StorageService
  ) {
    super(storageService);
    this.formSteps = this.getFromStorage() || new AccidentSteps();
  }

  getFromStorage(): AccidentSteps {
    const accidentStorage: AccidentStepsStorage = super.getFromStorage();
    const accidentSteps = new AccidentSteps();
    if (accidentStorage) {
      accidentSteps.step1 = { ...accidentStorage.step1 };
      accidentSteps.step2 = {
        injuryType: accidentStorage.step2 && accidentStorage.step2.injuryType,
        bodyPart: accidentStorage.step2 && accidentStorage.step2.bodyPart,
        description: accidentStorage.step2 && accidentStorage.step2.description,
        attachments: accidentStorage.step2 && this.attachmentService.convertAllToAttachments(accidentStorage.step2.attachments)
      };
      accidentSteps.step3 = { ...accidentStorage.step3 };
    }
    return accidentSteps;
  }

  getFormData(): Accident {
    return {
      ...this.formSteps.step1,
      ...this.formSteps.step2,
      ...this.formSteps.step3
    };
  }

  removeFromStorage(): void {
    this.formSteps = new AccidentSteps();
    return super.removeFromStorage();
  }

  async updateInStorage(): Promise<void> {
    const accidentStorage = new AccidentStepsStorage();
    accidentStorage.step1 = { ...this.formSteps.step1 };
    accidentStorage.step2 = {
      injuryType: this.formSteps.step2 && this.formSteps.step2.injuryType,
      bodyPart: this.formSteps.step2 && this.formSteps.step2.bodyPart,
      description: this.formSteps.step2 && this.formSteps.step2.description,
      attachments: this.formSteps.step2 && await this.attachmentService.convertAllToRawObjects(this.formSteps.step2.attachments)
    };
    accidentStorage.step3 = { ...this.formSteps.step3 };

    super.updateInStorage(accidentStorage);
  }

  convertStep1FormToObject(form: FormGroup): AccidentStep1 {
    return {
      date: form.value.date,
      location: form.value.location,
      injuredPerson: form.value.injuredPerson,
      reportedBy: form.value.reportedBy,
      witnesses: form.value.witnesses
    };
  }

  convertStep2FormToObject(form: FormGroup): AccidentStep2 {
    return {
      injuryType: form.value.injuryType,
      bodyPart: form.value.bodyPart,
      description: form.value.description,
      attachments: form.value.attachments
    };
  }

  convertStep3FormToObject(form: FormGroup): AccidentStep3 {
    return {
      measures: form.value.measures,
      remark: form.value.remark
    };
  }
}
