import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
// Components
import { TileHeight } from 'shared/components/tiles/tiles.component';
// Enums
import { MaterialDamage } from 'shared/enums/material-damage.enum';
// Interfaces
import { Tile } from 'shared/interfaces/tile.interface';
// Services
import { AlertService } from 'shared/services/alert.service';
import { EnvironmentalIncidentStepperService } from 'shared/services/environmental-incident-stepper.service';
import { EnvironmentalIncidentService } from 'shared/services/environmental-incident.service';

@Component({
  selector: 'gw-environmental-incident-step4',
  templateUrl: './environmental-incident-step4.component.pug',
  styleUrls: ['./environmental-incident-step4.component.scss']
})
export class EnvironmentalIncidentStep4Component implements OnInit {
  damagesForm: FormGroup;
  tiles: Array<Tile>;
  tileHeights = TileHeight;
  submitted = false;
  translation = {
    damages: {
      yes: '',
      no: ''
    }
  };
  isSaving: boolean;

  constructor(
    private alertService: AlertService,
    private environmentalIncidentService: EnvironmentalIncidentService,
    private environmentalIncidentStepperService: EnvironmentalIncidentStepperService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private incidentStepperService: EnvironmentalIncidentStepperService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    await this.fetchTranslation();
    this.createTiles();
    this.initForm();
  }

  private initForm() {
    const dataStep4 = this.incidentStepperService.formSteps.step4;
    this.damagesForm = this.formBuilder.group({
      damages: [dataStep4 && dataStep4.damages || '', Validators.required]
    });
    if (dataStep4 && dataStep4.damagesDescription) {
      this.addDescriptionControl(dataStep4.damagesDescription);
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  private createTiles(): void {
    this.tiles = [
      {
        id: MaterialDamage.yes,
        svgUrl: '/assets/icons/sprite-measures.svg#check-circle',
        translationString: this.translation.damages.yes,
        smallIcon: true,
      },
      {
        id: MaterialDamage.no,
        svgUrl: '/assets/icons/sprite-measures.svg#cross',
        translationString: this.translation.damages.no,
        smallIcon: true,
      },
    ];
  }

  toggleTile(tile: Tile): void {
    if (this.damagesForm.controls.damages.value !== tile.id) {
      this.damagesForm.controls.damages.setValue(tile.id);

      if (tile.id === MaterialDamage.yes) {
        this.addDescriptionControl();
      } else {
        this.removeDescriptionControl();
      }
    }
  }

  async submitForm(form: FormGroup): Promise<void> {
    this.submitted = true;

    if (form.valid) {
      this.isSaving = true;
      await this.updateStep(form);
      this.environmentalIncidentService.reportEnvironmentalIncident(
        this.environmentalIncidentStepperService.getFormData()
      )
        .then(() => {
          this.alertService.success();
          this.router.navigate(['/esh']);
        })
        .catch(() => {
          this.alertService.error();
          this.isSaving = false;
        });
    }
  }

  updateStep(form: FormGroup): void {
    this.incidentStepperService.formSteps.step4 = this.incidentStepperService.convertStep4FormToObject(form);
    this.incidentStepperService.updateInStorage();
  }

  private addDescriptionControl(description = '') {
    this.damagesForm.addControl('description', new FormControl(description, Validators.required));
    this.changeDetector.detectChanges();
  }

  private removeDescriptionControl() {
    this.damagesForm.removeControl('description');
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'GLOBAL.ANSWER.YES',
      'GLOBAL.ANSWER.NO',
    ]).toPromise();
    this.translation.damages.yes = translation['GLOBAL.ANSWER.YES'];
    this.translation.damages.no = translation['GLOBAL.ANSWER.NO'];
  }
}
