import { Component, Input } from '@angular/core';

import { UserProfile } from 'shared/models/user-profile.model';
import { AuthenticationService } from 'shared/services/authentication.service';

@Component({
  selector: 'gw-header',
  templateUrl: './header.component.pug',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() user: UserProfile;

  constructor(
    private authenticationService: AuthenticationService
  ) {}


  isLogOutVisible(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  logOut () {
    this.authenticationService.logOut();
  }
}
