import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import moment from 'moment';
// Components
import { DateTimePickerComponent } from 'shared/components/date-time-picker/date-time-picker.component';
// Directives
import { SimpleDropdownDirective } from 'shared/directives/simple-dropdown.directive';
// Models
import { Employee } from 'shared/models/employee.model';
// Services
import { EmployeeService } from 'shared/services/employee.service';
import { EnvironmentalIncidentStepperService } from 'shared/services/environmental-incident-stepper.service';

@Component({
  selector: 'gw-environmental-incident-step1',
  templateUrl: './environmental-incident-step1.component.pug',
  styleUrls: ['./environmental-incident-step1.component.scss']
})
export class EnvironmentalIncidentStep1Component implements OnInit {
  @ViewChild('dateTimeDropdown') dateTimeDropdown: SimpleDropdownDirective;
  @ViewChild('dateTimePicker') dateTimePicker: DateTimePickerComponent;
  employees: Array<Employee>;
  incidentForm: FormGroup;
  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
    private incidentStepperService: EnvironmentalIncidentStepperService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.employees = this.route.snapshot.data['employees'];
    this.initForm();
  }

  private initForm(): void {
    const stepData = this.incidentStepperService.formSteps.step1;
    this.incidentForm = this.formBuilder.group({
      date: [stepData && stepData.date || '', Validators.required],
      location: [stepData && stepData.location || '', Validators.required],
      reportedBy: [stepData && stepData.reportedBy || this.employeeService.getDefaultEmployee() || '', Validators.required]
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  applyDate(momentDate: moment.Moment): void {
    const date = moment(momentDate).format('YYYY-MM-DD HH:mm'); // ISO format
    this.incidentForm.controls['date'].setValue(date);
    if (this.dateTimeDropdown) {
      this.dateTimeDropdown.closeDropdown();
    }
  }

  keyDownDateTimePicker(event: KeyboardEvent): void {
    if (this.dateTimePicker) {
      this.dateTimePicker.keydown(event);
    }
  }

  submitForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateStep(form);
      this.goToNextStep();
    }
  }

  setControlValue(data: any, key: string): void {
    this.incidentForm.controls[key].setValue(data);
  }

  async updateStep(form: FormGroup): Promise<void> {
    this.incidentStepperService.formSteps.step1 = this.incidentStepperService.convertStep1FormToObject(form);
    await this.incidentStepperService.updateInStorage();
  }

  goToNextStep(): void {
    this.router.navigate(['/ehs/environmental-incident/incident-type'], { replaceUrl: true });
  }
}
